import { getParent, types, flow, isAlive, cast } from "mobx-state-tree";
import { values } from "mobx";
import axios from "../../store/axios";
import { FieldModel } from "../../store/ListField.model";

import DateTime from "../../store/DateTime";

export const AdminTraineeTestApplyStatusStoreInitial = {
    currentPage: 1,
};


const AdminTraineeTestApplyStatusEntry = types.model("AdminTraineeTestApplyEntry", {
    applyId: types.identifierNumber,
    userId: types.integer,
    displayName: types.string,
    trainerName: types.string,
    brand: types.string,
    dealer: types.string,
    branch: types.string,
    enterYear: types.string,
    testName: types.integer,
    trainerConfirmed: types.maybeNull(DateTime),
    confirmed: types.maybeNull(DateTime),
});

export const AdminTraineeTestApplyStatusStore = types
    .model("AdminTraineeTestApplyStatusStore", {
        list: types.array(AdminTraineeTestApplyStatusEntry),
        fields: types.array(FieldModel),
        currentPage: types.integer,
    })
    .views((self) => ({
        get List() {
            return values(self.list);
        },
        get Pages() {
            return self.pages;
        },
    }))
    .actions((self) => {
        return {
            download: flow(function* (id) {
                const response = yield axios.get(`/form/trainee-test-apply-download/${id}`, {
                    responseType: "blob", // important
                });
                
                console.log("word download response",response.headers);

                let fileName = response.headers["content-disposition"].split("filename=")[1];

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fileName); //or any other extension
                document.body.appendChild(link);
                link.click();
            }),

            fetch: flow(function* (page) {
                const rootStore = getParent(self, 1);
                const response = yield axios.get(
                        `/trainee-test-apply-status/list?_page=${page}&_limit=24&FileCount__=1`
                );
                console.log("trainee-test-apply-status/list?", response.data);
                self.list = response.data.list;
                self.pages = response.data.pages;
            }),
            fetchFiles: flow(function* (id) {
                const response = yield axios.get(
                    `/recordbook-file/files-by-recordbook/${id}`
                );
                console.log("fetchFilesByRecordBookId", id, response);
                // self.files = response.data;

                if (Array.isArray(response.data)) {
                    response.data.forEach(_ => self.files.put(_));
                } else {
                    console.error("fetchFilesByRecordBookId response data is not Array")
                }
            }),

            search: flow(function* (
                traineeName,
                trainerName,
                brand,
                dealer,
                branch,
                enterYear,
                trainerConfirmedState,
                testName,
                page,
                csv
            ) {
                console.log("page", page);

                let queryString = `/trainee-test-apply-status/list?_limit=30`;
                if (traineeName) {
                    queryString += `&displayName_like=${traineeName}`
                }
                if (trainerName) {
                    queryString += `&trainerName_like=${trainerName}`
                }
                if (brand) {
                    queryString += `&brand_like=${brand}`
                }
                if (dealer) {
                    queryString += `&dealer_like=${dealer}`
                }
                if (branch) {
                    queryString += `&branch_like=${branch}`
                }
                if (enterYear) {
                    queryString += `&enterYear__=${enterYear}`
                }
                if (testName) {
                    queryString += `&testName__=${testName}`
                }
                if (trainerConfirmedState) {
                    queryString += `&trainerConfirmed_isnull=${trainerConfirmedState}`
                }else if (trainerConfirmedState === false) {
                    queryString += `&trainerConfirmed_isnull=${trainerConfirmedState}`
                }

                if (csv) {
                    // console.log("csv download", queryString)
                    queryString += `&_csv=true`
                    console.log("queryString", queryString);
                    const response = yield axios.get(queryString, {
                        responseType: "blob", // important
                    });

                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "Report.csv"); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                } else {
                    queryString += `&_page=${page}`
                    console.log("queryString", queryString);
                    const response = yield axios.get(queryString);
                    // self.list.clear();
                    self.list.clear();
                    console.log("search list, pages", response.data.list, response.data.pages);
                    console.log("isAlive", isAlive(self));

                    // self.list = [...response.data.list];
                    // response.data.list.forEach(r => self.list.put(r))
                    // self.list.put = map();
                    self.pages = response.data.pages;
                    const totalCount = response.headers["X-Total-Count"];
                    console.log("total count", totalCount);
                    return response.data.list
                }

            }),
        };
    });

export default AdminTraineeTestApplyStatusStore;
