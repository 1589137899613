import { sygnet } from './sygnet'
import { logo } from './logo'
import { logoNegative } from './logo-negative'

import {
  cilFactory,
  cilGarage,
  cilColorFill,
  cilImageBroken,
  cilPushchair,
  cilGroup,
  cilApplicationsSettings,
  cilSpeedometer,
  cilSun,
  cilMoon,
  cilPlus,
  cilX,
  cilFile,
  cilCalendarCheck,
  cilUser,
  cilLockLocked,
  cilSettings,
  cilPencil,
  cilCalendar,
  cilSpreadsheet,
  cilTrash,
  cilInbox,
  cilEnvelopeOpen,
  cilLibraryAdd,
  cilBell,
  cilBasket,
  cilList,
  cilUserUnfollow,
  cilChartPie,
  cilHome,
  cilLocationPin,
  cibSkype,
  cilIndustry,
  cilCloudDownload,
  cilDataTransferDown,
  cilClock,
  cilBook,
  cilHouse,
} from "@coreui/icons";

import {
    cilCalendarMinus,
    cilCalendarEvent, 
    cilBullhorn,
    cilChalkboardTeacher,
    cilFileExcel
} from '@coreui/icons-pro'

export const icons = Object.assign(
  {},
  {
    sygnet,
    logo,
    logoNegative,
  },
  {
    cilFileExcel,
    cilHouse,
    cilFactory,
    cilChalkboardTeacher,
    cilCalendarMinus,
    cilBullhorn,
    cilCalendarEvent,
    cilGarage,
    cilImageBroken,
    cilColorFill,
    cilPushchair,
    cilGroup,
    cilCloudDownload,
    cilIndustry,
    cibSkype,
    cilLocationPin,
    cilHome,
    cilChartPie,
    cilUserUnfollow,
    cilBasket,
    cilList,
    cilBell,
    cilEnvelopeOpen,
    cilLibraryAdd,
    cilInbox,
    cilApplicationsSettings,
    cilSpeedometer,
    cilSun,
    cilPlus,
    cilMoon,
    cilX,
    cilFile,
    cilCalendarCheck,
    cilUser,
    cilLockLocked,
    cilSettings,
    cilPencil,
    cilCalendar,
    cilSpreadsheet,
    cilTrash,
    cilClock,
    cilDataTransferDown,
    cilBook,
  }
);
