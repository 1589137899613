import { getParent, types, flow, clone, applySnapshot } from "mobx-state-tree";
import { values } from "mobx";
import axios from "../../store/axios";
import dayjs from "dayjs";
import DateTime from "src/store/DateTime";

export const ValueLabelEntry = types.model("ValueLabelEntry", {
  value: types.integer,
  label: types.string,
});

export const TransferRequestStoreInitial = {
  entity: {
    brand: null,
    dealer: null,
    branch: null,
  },
  success: false,
  brands: [],
  dealers: [],
  branches: [],
  // selectedBrand: null,
  // selectedDealer: null,
  // selectedBranch: null,
};

export const TransferRequestEntity = types
  .model("TransferRequestEntity", {
    brand: types.maybeNull(ValueLabelEntry),
    dealer: types.maybeNull(ValueLabelEntry),
    branch: types.maybeNull(ValueLabelEntry),
    selectedBrandId: types.maybeNull(types.integer),
    selectedDealerId: types.maybeNull(types.integer),
    selectedBranchId: types.maybeNull(types.integer),
  })
  .actions((self) => {
    return {
      edit(name, value) {
        console.log("entity edit ", name, value);
        self[name] = value;
      },
      update: flow(function* () {
        // const data = {
        //   displayName_ENG: self.displayName_ENG,
        //   brandId: self.selectedBrandId,
        //   dealerId: self.selectedDealerId,
        //   branchId: self.selectedBranchId,
        //   birthday: self.birthday,
        // };
        const updateData = {
          brandId: self.selectedBrandId,
          dealerId: self.selectedDealerId,
          branchId: self.selectedBranchId,
        };
        console.log("dataToUpdate:", updateData);
        yield axios.put(`profile/trainer/request-transfer`, updateData);
        getParent(self, 1).setSuccess(true);
      }),
    };
  });

export const TransferRequestStore = types
  .model("TransferRequestStore", {
    entity: TransferRequestEntity,
    success: types.boolean,
    brands: types.array(ValueLabelEntry),
    dealers: types.array(ValueLabelEntry),
    branches: types.array(ValueLabelEntry),
    // selectedBrand: types.maybeNull(types.reference(ValueLabelEntry)),
    // selectedDealer: types.reference(ValueLabelEntry),
    // selectedBranch: types.reference(ValueLabelEntry),
  })
  .views((self) => ({
    get Brands() {
      return values(self.brands);
    },
    get Dealers() {
      return values(self.dealers);
    },
    get Branches() {
      return values(self.branches);
    },
  }))
  .actions((self) => {
    return {
      setSuccess(val) {
        self.success = val;
      },
      selectBrand(valueLabel) {
        self.selectedBrand = valueLabel;
      },
      selectDealer(valueLabel) {
        self.selectedDealer = valueLabel;
      },
      selectBranch(valueLabel) {
        self.selectedBranch = valueLabel;
      },
      reset() {
        applySnapshot(self, TransferRequestStoreInitial);
      },
      edit(name, value) {
        self[name] = value;
          console.log("TransferRequest edit", name, value);
      },
      fetchEdit: flow(function* (id) {
        let result;
        if (id) {
          result = yield axios.get(`profile/transfer-request/${id}`);
        } else {
          result = yield axios.get(`profile/transfer-request`);
        }
        console.log("fetchEdit", result.data);
        
        applySnapshot(self.entity, result.data);
        yield self.fetchDealer({ brandId: result.data.brand.value });
        yield self.fetchBranch({ dealerId: result.data.dealer.value });
      }),
      fetchBranch: flow(function* ({ dealerId }) {
        const result = yield axios.get(`profile/branches/${dealerId}`);
        console.log("fetch branches", result.data);
        self.branches = result.data;
      }),
      fetchDealer: flow(function* ({ brandId }) {
        console.log("fetch dealers brandId", brandId);
        const result = yield axios.get(`profile/dealers/${brandId}`);
        console.log("fetch dealers", result.data);
        self.dealers = result.data;
      }),
      fetchBrands: flow(function* () {
        const result = yield axios.get("profile/brands");
        console.log("fetch brands", result.data);
        self.brands = result.data;
        // applySnapshot(self.brands, result.data);
      }),
      handleBlur(fieldName) {
        self.touched[fieldName] = true;
      },

      requestTransfer: flow(function* () {
        const dataUpload = {
          brandId: self.entity.selectedBrandId,
          dealerId: self.entity.selectedDealerId,
          branchId: self.entity.selectedBranchId,
        };
        console.log("transfer-request", dataUpload);
        const result = yield axios.put("profile/transfer-request", dataUpload);
        self.success = true;
      }),

      transfer: flow(function* (id) {
        const dataUpload = {
          brandId: self.entity.selectedBrandId,
          dealerId: self.entity.selectedDealerId,
          branchId: self.entity.selectedBranchId,
        };
        console.log("transfer",id, dataUpload);
        const result = yield axios.put(`profile/transfer/${id}`, dataUpload);
        self.success = true;
      }),
    };
  });

export default TransferRequestStore;
