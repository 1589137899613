import {onPatch, types, flow } from "mobx-state-tree";
import _ from "lodash";
import axios from "../../store/axios";


export const TraineeTestApplyTrainerReviewStoreInitial = {
  absenceDays: 0,
  testName: 0,
  trainee: "",
  state: "",
};

export const TraineeTestApplyTrainerReviewStore = types
  .model("TraineeTestApplyReviewStore", {
    absenceDays: types.integer,
    testName: types.integer,
    trainee: types.string,
    state: types.string,
  })
  .actions((self) => {
    return {
      edit(name, value) {
        console.log('self, name, value', self, name, value)
        self[name] = value;
      },

      clearState() {
        self.state = "";
        self.absenceDays = "";
        self.trainee = "";
      },
      // fetch: flow(function* (traineeTestApplyId) {
      //   const result = yield axios.get(`/form/trainee-test-apply/${traineeTestApplyId}`);
      //   self = result.data;
      //   console.log("TraineeTestApplyTrainerReviewStore fetch", self);
      // }),
      fetchAbsenceDays: flow(function* (traineeTestApplyId) {
        const result = yield axios.get(`/form/trainee-test-apply-absenceDays/${traineeTestApplyId}`);
        self.absenceDays = result.data.absenceDays;
        self.testName = result.data.testName;
        console.log("TraineeTestApplyTrainerReviewStore fetchAbsenceDays", self.absenceDays);
      }),

      saveTrainerSignature: flow(function* ({ signature, traineeTestApplyId }) {
        try {
          const body = {
            trainerSignature: signature,
            absenceDays: self.absenceDays,
            traineeTestApplyId,
          };
          console.log("saveTrainerSignature body", body);
          yield axios.post(`/form/trainee-test-apply-trainer-review-submit`, body);
          self.state = "success";
          console.log("self.state", self.state);
        } catch (err) {
          self.state = "error";
        }
      }),

    };
  });


export default TraineeTestApplyTrainerReviewStore;
