import { types, flow, destroy, getParent } from "mobx-state-tree";
import { values } from "mobx";

export const ToasterStoreInitial = {
  list: [],
};

export const ToasterEntry = types
  .model("ToasterEntry", {
    message: types.string,
  })

export const ToasterStore = types
  .model("ToasterStore", {
    list: types.array(ToasterEntry),
  })
  .views((self) => ({
    get List() {
      return values(self.list);
    },
  }))
  .actions((self) => {
    return {
      add(message) {
        console.log('ToasterStore:add', message);
        self.list.push({message});
      },
    };
  });
