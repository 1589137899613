import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "./context/auth";

export default function PrivateRoute({ component: Component = null, render: Render = null, ...rest }) {
  const { authTokens } = useAuth();

    return (
      <Route
        {...rest}
        render={props =>
          authTokens ? (
            Render ? (
              Render(props)
            ) : Component ? (
              <Component {...props} />
            ) : null
          ) : (
            <Redirect to={{ pathname: '/login', state: { referer: props.location } }} />
          )
        }
      />
    );
  }
