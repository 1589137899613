import DateTime from "./DateTime";
import { types, flow, destroy, getParent } from "mobx-state-tree";
import axios from "./axios";
import { values } from "mobx";

export const MessageBoxStoreInitial = {
  list: [],
};

export const MessageBoxEntry = types
  .model("MessageBoxEntry", {
    id: types.identifierNumber,
    title: types.string,
    subTitle: types.maybeNull(types.string),
    created: DateTime,
    read: types.boolean,
    url: types.string,
    recordBookId: types.maybeNull(types.integer),
    lessonId: types.maybeNull(types.integer),
  })
  .actions((self) => {
    return {
      // readMark: flow(function* (){
      //   const result = yield axios.put(`message-box/read-mark/${self.id}`);
      //   getParent(self, 2).remove(self);
      // }
      // )
    };
  });

export const MessageBoxStore = types
  .model("MessageBoxStore", {
    list: types.array(MessageBoxEntry),
  })
  .views((self) => ({
    get List() {
      return values(self.list);
    },
  }))
  .actions((self) => {
    return {
      fetch: flow(function* () {
        const result = yield axios.get(`/message-box/unread`);
        self.list = result.data;
      }),
      remove(item) {
        destroy(item);
      },
      removeMessages: flow(function* (messageId) {
        const response = yield axios.delete(
          `/message-box/${messageId}`
        );
        const obj = self.list.find((item) => item.id === messageId);
        if (obj) {
          destroy(obj);
        }
      }) ,
      readRecordBookMark: flow(function* (recordBookId) {
        const response = yield axios.put(
          `/message-box/recordbook-read-mark/${recordBookId}`
        );
        const obj = self.list.find((item) => item.recordBookId === recordBookId);
        if (obj) {
          destroy(obj);
        }
      }),
      readLessonMark: flow(function* (lessonId) {
        const response = yield axios.put(
          `/message-box/lesson-read-mark/${lessonId}`
        );
        const obj = self.list.find((item) => item.id === response.data);
        if (obj) {
          destroy(obj);
        }
      }),
      readNoticeMark: flow(function* (noticeId) {
        const response = yield axios.put(
          `/message-box/notice-read-mark/${noticeId}`
        );
        const obj = self.list.find((item) => item.id === response.data);
        if (obj) {
          destroy(obj);
        }
      }),
    };
  });
