import { getParent, types, flow, isAlive, cast } from "mobx-state-tree";
import { values } from "mobx";
import axios from "../../store/axios";
import { FieldModel } from "../../store/ListField.model";

import DateTime from "../../store/DateTime";

export const AdminTrainerListStoreInitial = {
    list: [],
    currentPage: 1,
};


const AdminTrinerListEntry = types.model("AdminTraineeTestApplyEntry", {
    id: types.identifierNumber,
    displayName: types.string,
    brand: types.string,
    dealer: types.string,
    branch: types.string,
    enterYear: types.integer,
    highSchool: types.string,
    university: types.string,
    phoneNumber: types.string,
    birthday: types.Date,
    professionality: types.string,
    professionalityId: types.integer,
});

export const AdminTrainerListStore = types
    .model("AdminTrainerListStore", {
        list: types.array(AdminTrinerListEntry),
        fields: types.array(FieldModel),
        currentPage: types.integer,
    })
    .views((self) => ({
        get List() {
            return values(self.list);
        },
        get Pages() {
            return self.pages;
        },
    }))
    .actions((self) => {
        return {
            decline: flow(function* (id) {
                // const rootStore = getParent(self, 1);
                console.log("quit", id);
                const url = `/admin-trainer/quit/${id}`;
                const response = yield axios.put(
                    url
                );
                console.log(url, response.data);
            }),
            confirm: flow(function* (id) {
                // const rootStore = getParent(self, 1);
                console.log("confirm", id);
                const url = `/transfer-request/allow-transfer/${id}`;
                const response = yield axios.put(
                    url
                );
            }),
            search: flow(function* (
                displayName,
                brand,
                dealer,
                branch,
                professionalityId,
                page
                ) {
                // const rootStore = getParent(self, 1);
                console.log("search page", page);
                let queryString = `/admin-trainer/list?_page=${page}&_limit=24`;

                if (displayName) {
                    queryString += `&displayName_like=${displayName}`;
                }
                if (brand) {
                    queryString += `&brand_like=${brand}`
                }
                if (dealer) {
                    queryString += `&dealer_like=${dealer}`
                }
                if (branch) {
                    queryString += `&branch_like=${branch}`
                }
                if (professionalityId && professionalityId !== "ALL") {
                    queryString += `&professionality__=${professionalityId}`
                }
                console.log("queryString ", queryString);
                const response = yield axios.get(
                    queryString
                );
                console.log("AdminTrinerList ", response.data);
                self.pages = response.data.pages;
                 // Convert UTC birthday to local time
                const list = response.data.list.map(item => {
                    const date = new Date(item.birthday);
                    item.birthday = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
                    return item;
                });
                return list; 
            }),
        };
    });

export default AdminTrainerListStore;
