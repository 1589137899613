import { getParent, types, flow, clone, applySnapshot } from "mobx-state-tree";
import { values } from "mobx";
import axios from "../../store/axios";
import dayjs from "dayjs";
import DateTime from "src/store/DateTime";
import {TransferRequestStatusModel, TransferRequestStatusModelInitial} from "../transfer_request/TransferRequestStatusModel";

const DateTimeInit = "0001-01-00";

export const ValueLabelEntry = types.model("ValueLabelEntry", {
  value: types.integer,
  label: types.string,
});

export const TrainerCreateStoreInitial = {
  entity: {
    email: "",
    familyName_ENG: "",
    givenName_ENG: "",
    homeTown_ENG: "",
    phoneNumber: "",
    birthday: DateTimeInit,
    displayName: "",
    organization: "",
    professionality: "",
    TTT1Start: DateTimeInit,
    TTT1End:DateTimeInit,
    TTT2Start: DateTimeInit,
    TTT2End:DateTimeInit,
    practicalTest: DateTimeInit,
    writtenTest:DateTimeInit,
    certificateIssue: DateTimeInit,
    retrainingStart: DateTimeInit,
    retrainingEnd: DateTimeInit,
    assessorTrainingStart: DateTimeInit,
    assessorTrainingEnd: DateTimeInit,
  },
  brands: [],
  success: false,
  transferRequestStatus: TransferRequestStatusModelInitial,
  // selectedBrand: "",
  // selectedDealer: "",
  // selectedBranch: "",
};

export const TrainerProfileEntity = types
  .model("TrainerProfileEntity", {
    email: types.string,
    givenName_ENG:  types.maybeNull(types.string),
    familyName_ENG:  types.maybeNull(types.string),
    // brand: types.maybeNull(types.string),
    // dealer: types.maybeNull(types.string),
    // branch: types.maybeNull(types.string),
    birthday: types.maybeNull(DateTime),
    displayName: types.string,
    homeTown_ENG:  types.maybeNull(types.string),
    homeTown:  types.maybeNull(types.string),
    phoneNumber:  types.maybeNull(types.string),
    organization: types.string,
    professionality: types.string,
    TTT1Start: types.maybeNull(DateTime),
    TTT1End: types.maybeNull(DateTime),
    TTT2Start: types.maybeNull(DateTime),
    TTT2End: types.maybeNull(DateTime),
    writtenTest: types.maybeNull(DateTime),
    practicalTest: types.maybeNull(DateTime),
    certificateIssued: types.maybeNull(DateTime),
    retrainingStart: types.maybeNull(DateTime),
    retrainingEnd: types.maybeNull(DateTime),
    assessorTrainingStart: types.maybeNull(DateTime),
    assessorTrainingEnd: types.maybeNull(DateTime),
  })
  .actions((self) => {
    return {
      edit(name, value) {
        console.log("on value changed", name, value);
        self[name] = value;
      },
      update: flow(function* () {
        // const data = {
        //   displayName_ENG: self.displayName_ENG,
        //   brandId: self.selectedBrandId,
        //   dealerId: self.selectedDealerId,
        //   branchId: self.selectedBranchId,
        //   birthday: self.birthday,
        // };
        const updateData = {
          homeTown_ENG: self.homeTown_ENG,
          familyName_ENG: self.familyName_ENG,
          givenName_ENG: self.givenName_ENG,
          homeTown: self.homeTown,
          birthday: self.birthday,
          phoneNumber: self.phoneNumber,
          email: self.email,
        }
        console.log("dataToUpdate:", updateData);
        yield axios.put(`profile/trainer`, updateData);
        getParent(self,1).setSuccess(true);
      }),
    };
  });


export const TrainerCreateStore = types
  .model("TrainerCreateStore", {
    entity: TrainerProfileEntity,
    success: types.boolean,
    transferRequestStatus: TransferRequestStatusModel,
    brands: types.array(ValueLabelEntry),
    dealers: types.array(ValueLabelEntry),
    branches: types.array(ValueLabelEntry),
    // selectedBrand: types.maybeNull(types.reference(ValueLabelEntry)),
    // selectedDealer: types.reference(ValueLabelEntry),
    // selectedBranch: types.reference(ValueLabelEntry),
  })
  .views((self) => ({
    // get Brands() {
    //   return values(self.brands);
    // }
    // ,
    // get Dealers() {
    //   return values(self.dealers);
    // }
    // ,
    // get Branches() {
    //   return values(self.branches);
    // }
  }))
  .actions((self) => {
    return {
      setSuccess(val) {
        self.success = val;
      },
      selectBrand(valueLabel) {
        self.selectedBrand = valueLabel;
      },
      selectDealer(valueLabel) {
        self.selectedDealer = valueLabel;
      },
      selectBranch(valueLabel) {
        self.selectedBranch = valueLabel;
      },
      reset() {
        applySnapshot(self, TrainerCreateStoreInitial);
      },
      edit(name, value) {
        self[name] = value;
      },
      fetchEdit: flow(function* (id) {
        let result;
          if(id){
        result = yield axios.get(`profile/trainer/${id}`);
          }else{
        result = yield axios.get(`profile/trainer`);
          }
        console.log("fetchEdit", result.data);
        applySnapshot(self.entity, result.data);
      }),
      fetchBranch: flow(function* ({ dealerId }) {
        const result = yield axios.get(`profile/branches/${dealerId}`);
        console.log("fetch branches", result.data);
        self.branches = result.data
      }),
      fetchDealer: flow(function* ({ brandId }) {
        console.log("fetch dealers brandId", brandId);
        const result = yield axios.get(`profile/dealers/${brandId}`);
        console.log("fetch dealers", result.data);
        self.dealers = result.data
      }),
      fetchBrands: flow(function* () {
        const result = yield axios.get("profile/brands");
        console.log("fetch brands", result.data);
        self.brands = result.data
        // applySnapshot(self.brands, result.data);
      }),
      handleBlur(fieldName) {
        self.touched[fieldName] = true;
      },
      update: flow(function* (id) {
        console.log("update ", self.toJSON());
          if(id){
              yield axios.put(`profile/trainer/${id}`, self);
          }else{
              yield axios.put(`profile/trainer`, self);
          }
      }),
      fetchTransferRequestStatus: flow(function*(){
        console.log("transferRequestStatus ");
        const result = yield axios.get("profile/transfer-request-status");
        self.transferRequestStatus = result.data;
      })
    };
  });

export default TrainerCreateStore;
