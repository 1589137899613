import axios from "axios";
// import * as RootNavigation from "../navigation/RootNavigation.js";
import uuid from 'react-uuid'
// let SERVER_URL;
// if (__DEV__) SERVER_URL = Constants.manifest.extra.dev_endpoint;
// else SERVER_URL = Constants.manifest.extra.prod_endpoint;

// const SERVER_URL = process.env.REACT_APP_API_SERVER;
const SERVER_URL = window._env_.API_URL;

const AxiosInstance = axios.create({
  baseURL: SERVER_URL,
  timeout: 80000,
  headers: {
    "Content-Type": "application/json",
  },
});
// LocalstorageService
// const localStorageService = LocalStorageService.getService();
// Add a request interceptor
AxiosInstance.interceptors.request.use(
  async (config) => {
    const tokens = sessionStorage.getItem("tokens");
    if (tokens) {
    //   console.log("AxiosInstance request userInfo from AsyncStorage", userInfo);
    const authToken = JSON.parse(sessionStorage.getItem("tokens")).authToken;
      config.headers["Authorization"] =
        "Bearer " + authToken;
    }
    return config;
  },
  (error) => {
    console.error("AxiosInstance interceptors request error", error);
    Promise.reject(error);
  }
);

//Add a response interceptor

AxiosInstance.interceptors.response.use(
  (response) => {
    // console.log("AxiosInstance response interceptor", response);
    return response;
  },
  async function (error) {
    console.log("AxiosInstance.interceptors.response.error",error.response);
    if (error.response.status === 404) {
       console.log("Not Found Error :", error.config.url);
    }
    if(error.response.status === 400 && error.message === "ACCOUNT_NOT_FOUND"){
        //auth failure wrong password
    //   RootNavigation.push("Auth");
      alert('아이디나 패스워드가 틀렸습니다.');
      return Promise.reject(error);
    }

    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      originalRequest.url === SERVER_URL + "/auth/token"
    ) {
      console.log(
        "error.response.status === 401 && originalRequest.url === SERVER_URL + '/auth/token'"
      );
      // RootNavigation.push("Auth");
      return Promise.reject(error);
    }

    if (error.response.status === 401 ) {
       console.log("error.response.status === 401 headers", error.response.headers) ;
    }
    //    if (error.response.status === 401 && !originalRequest._retry) {
    if (
      error.response.status === 401 &&
      error.response.headers["token-expired"] === "true"
    ) {
      console.log(
        "error.response.status === 401 && error.response.headers['token-expired'] === 'true'"
      );
      originalRequest._retry = true;
      const old_refreshToken = sessionStorage.getItem("refreshToken");
      const old_authToken = sessionStorage.getItem("authToken");
      if (old_refreshToken) {
        // const userInfo = JSON.parse(userInfoStr);
        console.log("axios refresh token ", old_refreshToken);
        const device_id = sessionStorage.getItem("deviceId");
        return axios
          .post(SERVER_URL + "/auth/refresh_token", {
            refreshToken: old_refreshToken,
            DeviceId: device_id ?? uuid(),
            jwtToken: old_authToken,
          })
          .then(async (res) => {
            if (res.status === 200) {
              console.log("axios refresh res", res);
              const {authToken, refreshToken} = res.data;
              sessionStorage.setItem("authToken", authToken);
              sessionStorage.setItem("refreshToken", refreshToken);
              axios.defaults.headers.common['Authorization'] = 'Bearer ' + authToken;
                console.log("originalRequest", originalRequest);
              return AxiosInstance(originalRequest);
            }
          })
          .catch((error) => {
            console.log("axios refresh error", error);
            // RootNavigation.navigate("Auth");
          });
      }
    }
    return Promise.reject(error);
  }
);

export default AxiosInstance;
