import { types, flow, applySnapshot } from "mobx-state-tree";
import axios from "./axios";

export const ProfileStoreInitial = {
    userId: 0,
    displayName: "",
    enterYear: null,
    email: "",
    brand: null,
    dealer: null,
    branch: null,
    userType: "",
    traineeTestApplyState: null,
}

export const TraineeTestApplyStateEnum = types.enumeration("traineeTestApplyStateEnum", ["NotSubmitted, SubmitTrainer, ConfirmTrainer, ConfirmCenter"]);

export const TraineeTestApplyState = types.model("TraineeTestApplyState",{
  firstStatus: types.string,
  secondStatus: types.string,
  firstId: types.integer,
  secondId: types.integer,
})

export const TraineeInfo = types.model("TraineeInfo",{
    displayName: types.string
})

export const ProfileStore = types
  .model("ProfileStore", {
    userId: types.integer,
    displayName: types.string,
    enterYear: types.maybeNull(types.integer),
    email: types.string,
    brand: types.maybeNull(types.string),
    dealer: types.maybeNull(types.string),
    branch: types.maybeNull(types.string),
    userType: types.string,
    traineeTestApplyState: types.maybeNull(TraineeTestApplyState),
  })
  .actions((self) => {
    return {
      setDisplayName(displayName) {
        self.displayName = displayName
      }
      ,
      save(values){
        applySnapshot(self, values);
      },
      getTestApplyState: flow(function *(){
        const result = yield axios.get('/profile/trainee/test-apply-state');
        console.log(`getTestApplyState ${JSON.stringify(result.data)}`);
        self.traineeTestApplyState = result.data;
      }),
      getProfileById: flow(function *(id){
        const result = yield axios.get(`/profile/trainee/{id}`);
        self.traineeTestApplyState = result.data;
      })
    };
  });
