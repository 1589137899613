import { types, flow } from "mobx-state-tree";
import axios from "../../store/axios";
import FileStore from "../../store/FileStore";

export const UploadModalStoreInitial = {
  templateId: 0,
  files: { list: [] },
  state: ""
};

export const UploadModalStore = types
  .model("UploadModalStore", {
    templateId: types.integer,
    files: FileStore,
    state: types.string
  })
  .views((self) => ({
    get Files() {
      return self.files.list.map((file) => ({ id: file.id, name: file.name }));
    },
  }))
  .actions((self) => {
    return {
      create: flow(function* ({templateId}) {
        console.log("create ", self.toJSON());
        const dataToUpload = {
          templateId,
          fileId: self.files.list[0]?.id,
        };
        console.log("dataToUpload:", dataToUpload);
        try{
          const response = yield axios.post("form/upload", dataToUpload);
          self.state = "success";
          return response.data;
        }catch(err){
          self.state = "error";
        }
      }),
      clearFiles() {
        self.files.list.clear();
        self.state = "";
      },

    };
  });

export default UploadModalStore;
