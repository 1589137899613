import { types, applySnapshot } from "mobx-state-tree";

export const UserInfoStoreInitial = {
    userId: 0,
    displayName: "",
    enterYear: 0,
    branch: "",
    dealer: "",
    brand: "",
};

export const UserInfoStore = types
  .model("UserInfoStore", {
    userId: types.integer,
    displayName: types.string,
    enterYear: types.integer,
    branch: types.string,
    dealer: types.string,
    brand: types.string,
  })
  .actions((self) => {
    return {
      setUserInfo(values){
        applySnapshot(self, values);
      }
    };
  });
