import { getParent, types, flow, isAlive, cast } from "mobx-state-tree";
import { values } from "mobx";
import axios from "../../store/axios";
import { FieldModel } from "../../store/ListField.model";


export const AdminRecordBookListByTraineeStoreInitial = {
    fields: [
        // { key: "traineeId", label: "트레이니ID" },
        { key: "traineeName", label: "이름" },
        { key: "brandName", label: "브랜드" },
        { key: "dealerName", label: "딜러사" },
        { key: "branchName", label: "서비스센터" },
        { key: "stype", label: "기준주" },
        { key: "enterYear", label: "입사년도" },
        {
            key: "ratio",
            label: "진행율",
            sorter: false,
            filter: false,
            _style: { width: "1%" },
        },
    ],
    currentPage: 1,
};


const AdminRecordBookListByTraineeEntry = types.model("AdminRecordBookListByTraineeEntry", {
    userId: types.identifierNumber,
    traineeName: types.string,
    brand: types.string,
    brandId: types.integer,
    dealer: types.string,
    dealerId: types.integer,
    branch: types.string,
    branchId: types.integer,
    enterYear: types.string,
    professionality: types.string,
    sType: types.string,
    requiredDays: types.integer,
    recordEndCnt: types.integer,
    recordPercent: types.number,
    recordEndSumCnt: types.integer,
    recordSumPercent: types.number,
    subjectCnt: types.integer,
    notYetCategoryCnt: types.integer,
});

export const AdminRecordBookListByTraineeStore = types
    .model("AdminRecordBookListByTraineeStore", {
        list: types.array(AdminRecordBookListByTraineeEntry),
        fields: types.array(FieldModel),
        currentPage: types.integer,
    })
    .views((self) => ({
        get List() {
            return values(self.list);
        },
        get Pages() {
            return self.pages;
        },
    }))
    .actions((self) => {
        return {
            fetch: flow(function* (page) {
                const rootStore = getParent(self, 1);
                let response;
                if (rootStore.profileStore.userType === "AHKKorea" || rootStore.profileStore.userType === "HRDK") {
                    response = yield axios.get(
                        `/recordbook-file/list?_page=${page}&_limit=24&FileCount__=1`
                    );
                } else if (rootStore.profileStore.userType === "BrandAdmin") {
                    response = yield axios.get(
                        `/recordbook-file/list?_page=${page}&_limit=24`
                    );
                }
                console.log(response.data);
                self.list = response.data.list;
                self.pages = response.data.pages;
            }),
            excelDownload: flow(function* () {

            }),
            fetchFiles: flow(function* (id) {
                const response = yield axios.get(
                    `/recordbook-file/files-by-recordbook/${id}`
                );
                console.log("fetchFilesByRecordBookId", id, response);
                // self.files = response.data;

                if (Array.isArray(response.data)) {
                    response.data.forEach(_ => self.files.put(_));
                } else {
                    console.error("fetchFilesByRecordBookId response data is not Array")
                }
            }),
            setStartDate: flow(function* (startDate) {
                self.startDate = startDate;
                yield self.search();
            }),
            setEndDate: flow(function* (endDate) {
                self.endDate = endDate;
                yield self.search();
            }),

            search: flow(function* (
                traineeName,
                brand,
                dealer,
                branch,
                enterYear,
                stype,
                ratio,
                page,
                csv
            ) {
                console.log("page", page);

                let queryString = `/recordbook-by-trainee/list?_limit=30`;
                if (traineeName) {
                    queryString += `&traineeName_like=${traineeName}`
                }
                if (brand) {
                    queryString += `&brand_like=${brand}`
                }
                if (dealer) {
                    queryString += `&dealer_like=${dealer}`
                }
                if (branch) {
                    queryString += `&branch_like=${branch}`
                }
                if (enterYear) {
                    queryString += `&enterYear__=${enterYear}`
                }
                if (stype) {
                    queryString += `&stype_like=${stype}`
                }
                if (ratio) {
                    queryString += `&recordPercent__=${ratio}`
                }

                if (csv) {
                    console.log("csv download", queryString)
                    queryString += `&_csv=true`
                    const response = yield axios.get(queryString, {
                        responseType: "blob", // important
                    });

                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "Report.csv"); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                } else {
                    queryString += `&_page=${page}`
                    const response = yield axios.get(queryString);
                    // self.list.clear();
                    self.list.clear();
                    console.log("search list, pages", response.data.list, response.data.pages);
                    console.log("isAlive", isAlive(self));

                    // self.list = [...response.data.list];
                    // response.data.list.forEach(r => self.list.put(r))
                    // self.list.put = map();
                    self.pages = response.data.pages;
                    const totalCount = response.headers["X-Total-Count"];
                    console.log("total count", totalCount);
                    return response.data.list
                }
                console.log("queryString", queryString)

            }),
        };
    });

export default AdminRecordBookListByTraineeStore;
