import { types, flow, getParent } from "mobx-state-tree";

export const SubjectModel = types.model("SubjectModel", {
  id: types.identifierNumber,
  title: types.string,
  charIndex: types.string,
});

export const SemesterModel = types.model("SemesterModel", {
  id: types.identifierNumber,
  semesterType: types.enumeration("semesterType", ["가", "나", "전체"]),
  requiredDays: types.number,
  subjects: types.array(SubjectModel),
});

export const CategoryModel = types.model("CategoryModel", {
  id: types.identifierNumber,
  title: types.string,
  // brandType: types.enumeration("brandType", ["passenger", "nonpassenger"]),
  chapter: types.enumeration("chapter", ["A", "B", "D", "국내규정"]),
  semesters: types.array(SemesterModel),
  source: types.string,
});

export const CategoryModelInitial = {}

// export const CategoryModelInitial = [
//   {
//     id: 0,
//     title: "",
//     source: "",
//     chapter: "A",
//     semesters: [
//       {
//         id: 0,
//         semesterType: "가",
//         requiredDays: 0,
//         subjects: [
//           {
//             id: 0,
//             title: "",
//             charIndex: "",
//             recordBookStates: [
//               {
//                 traineeId: 0,
//                 traineeName: "",
//                 state: "NOT_START",
//                 days: 0,
//               },
//             ],
//           },
//         ],
//       },
//     ],
//   },
// ];
