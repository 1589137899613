import { getParent, types, flow, clone } from "mobx-state-tree";
import { values } from "mobx";
import dayjs from "dayjs";
import axios from "../../store/axios";
import DateTime from "../../store/DateTime";
import { FieldModel } from "../../store/ListField.model";
import cloneDeep from "lodash.clonedeep";
// import { custom } from "mobx-state-tree/dist/internal";


export const TraineeRecordBookListStoreInitial = {
  list: [],
  fields: [
    { key: "date", label: "일자", _style: {width:"20%"}  },
    { key: "subjectTitle", label: "주제", _style: {width:"30%"} },
    { key: "subjectIndex", label: "Index" , _style: {width:"20%"} },
    { key: "status", label: "상태" , _style: {width:"10%"} },
  ],
};

const IdNameEntry = types.model("IdNameEntry", {
  id: types.integer,
  displayName: types.string,
});

const TraineeRecordBookListEntry = types.model("TraineeRecordBookListEntry", {
  id: types.identifierNumber,
  date: DateTime,
  subjectTitle: types.string,
  subjectIndex: types.string,
  status: types.maybeNull(types.string),
  lessonId: types.maybeNull(types.integer),
});

export const TraineeRecordBookListStore = types
  .model("TraineeRecordBookListStore", {
    list: types.array(TraineeRecordBookListEntry),
    listExcel: types.array(TraineeRecordBookListEntry),
    fields: types.array(FieldModel),
  })
  .views((self) => ({
    get List() {
      return values(self.list);
    },
    get ExcelData(){
      return values(self.listExcel);
    //  const data = [];
      //
      // const list = values(self.listExcel);
      // for (let i = 0; i < list.length; i++)
      // {
      //   data.push({subjectIndex:list[i].subjectIndex, subjectTitle : list[i].subjectTitle,  date: dayjs(list[i].date).format('YYYY-MM-DD'), status: list[i].status})
      // }
      // return data;
    },
    get Pages() {
      return self.pages;
    }
  }))
  .actions((self) => {
    return {
      fetch: flow(function* (page) {
        let response;
          response = yield axios.get(
            `/record-book/by-trainee?page=${page}&limit=24`
          );
        console.log(response.data);
        self.list = response.data.list;
        self.pages = response.data.pages;
      }),

      fetchExcel: flow(function* (){
        let response;
          response = yield axios.get(
            `/record-book/by-trainee-all`
          );
        console.log(response.data);
        self.listExcel = response.data.list;
      }),

      clearExcel(){
        self.listExcel = undefined;
      }

    };
  });

export default TraineeRecordBookListStore;
