import { flow, types, getParent } from "mobx-state-tree";
import axios from "./axios";
import { values } from "mobx";

export const Badge = types.model("Badge", {
  color: types.string,
  text: types.string,
})

export const NavTitle = types.model("NavTitle", {
  _tag: types.string,
  _children: types.array(types.string),
  badge: types.maybeNull(Badge),
});


export const NavItem = types.model("NavItem", {
  _tag: types.enumeration("tag", ["CSidebarNavItem", "CSidebarNavTitle"]),
  name: types.maybeNull(types.string),
  to: types.maybeNull(types.string),
  icon: types.maybeNull(types.string),
  badge: types.maybeNull(Badge),
  _children: types.maybeNull(types.array(types.string)),
});

export const NavDropdown = types.compose(NavItem, types.model("NavDropdown", {
  _tag: types.string,
  _children: types.array(types.late(() => NavItem)),
})
);

export const NavNode = types.compose(NavItem, NavDropdown).named("NavNode");

export const NavStoreInitial = {
  list : [],
  traineeList: [],
  isLoading : false,
  brands : [],
}

export const BranchNode = types.model("BranchNode", {
    id : types.identifierNumber,
    name: types.string,
})

export const DealerNode = types.model("DealerNode", {
  _tag: types.string,
  _children: types.array(types.late(() => NavItem)),
  icon: types.maybeNull(types.string),
//   id: types.identifierNumber,
  name: types.string,
//   branches: types.array(BranchNode),
});

export const BrandNode = types.model("BrandNode", {
  _tag: types.string,
  _children: types.array(types.late(() => DealerNode)),
  icon: types.maybeNull(types.string),
  name: types.string,
  // dealers: types.array(DealerNode),
});

export const NavStore = types
  .model("NavStore", {
    list: types.array(NavItem),
    traineeList: types.array(NavDropdown),
    isLoading: types.boolean,
    brands: types.array(BrandNode),
  })
  .views((self) => ({
    get List() {
    //   return [...values(self.list), ...values(self.traineeList), ...values(self.brands)]; 
      return [...values(self.list), ...values(self.traineeList)]; 
    },
    get Brands() {
        return values(self.brands);
    }
  }))
  .actions((self) => ({
    fetch: flow(function* () {
      self.isLoading = true;
      const result = yield axios.get(`nav`);
      console.log("nav", result.data);
      self.list = result.data;
      self.isLoading = false;
    }),
    fetchTrainer: flow(function* () {
      self.isLoading = true;
      const profileStore = getParent(self, 1).profileStore;
      if (profileStore.userType === "Trainer") {
        const result = yield axios.get(`nav/trainer`);
        console.log("navTrainer", result.data);
        self.traineeList = result.data;
        self.isLoading = false;
      }
    }),

    fetchAHKKore: flow(function* () {
        const result = yield axios.get(`nav/hierarchy`);
        // console.log("fetchHierarchy", result.data);
        self.brands = result.data;
    })
  }));

export default NavStore;
