import { getParent, types, flow } from "mobx-state-tree";
import axios from "../../store/axios";

export const AdminTraineeStoreInitial = {
  list: [],
  fields: [
    { key: "displayName", label: "이름" },
    { key: "enterYear", label: "년도" },
    { key: "brand", label: "브랜드" },
    { key: "dealer", label: "딜러" },
    { key: "branch", label: "센터" },
    { key: "status", label: "상태" },
  ],
};



export const AdminTraineeStore = types
  .model("AdminTraineeStore", {
  })
  .views((self) => ({
  }))
  .actions((self) => {
    return {
      fetch: flow(function* (traineeId) {

      }),
    };
  });

export default AdminTraineeStore;
