import { getParent, types, flow, isAlive, cast } from "mobx-state-tree";
import { values } from "mobx";
import dayjs from "dayjs";
import axios from "../../store/axios";
import DateTime from "../../store/DateTime";
import { FieldModel } from "../../store/ListField.model";
import { FileModel } from "src/store/FileStore";

export const AdminRecordBookListStoreInitial = {
    fields: [
        // { key: "traineeId", label: "트레이니ID" },
        { key: "displayName", label: "이름" },
        { key: "date", label: "일자" },
        { key: "brand", label: "브랜드" },
        { key: "dealer", label: "딜러사" },
        { key: "branch", label: "서비스센터" },
        {
            key: "fileCount",
            label: "첨부파일",
            sorter: false,
            filter: false,
            _style: { width: "1%" },
        },
        {
            key: "show_details",
            label: "내용",
            sorter: false,
            filter: false,
            _style: { width: "1%" },
        },
    ],
    traineeName: "",
    brand: "",
    branch: "",
    startDate: new Date(),
    endDate: new Date(),
    fileExist: false,
    currentPage: 1,
};

const IdNameEntry = types.model("IdNameEntry", {
    id: types.integer,
    name: types.string,
});

const AdminRecordBookListEntry = types.model("AdminRecordBookListEntry", {
    recordBookId: types.identifierNumber,
    traineeId: types.integer,
    displayName: types.string,
    date: types.maybeNull(DateTime),
    brand: types.string,
    brandId: types.integer,
    dealer: types.string,
    dealerId: types.integer,
    branch: types.string,
    branchId: types.integer,
    content: types.maybeNull(types.string),
    //   files: types.array(FileEntry),
    fileCount: types.integer,
});

export const FileEntry = types.compose(types.model({
    recordBookId: types.integer,
}), FileModel).named("FileEntry");

export const AdminRecordBookListStore = types
    .model("AdminRecordBookListStore", {
        list: types.array(AdminRecordBookListEntry),
        fields: types.array(FieldModel),
        files: types.map(FileEntry),
        traineeName: types.string,
        brand: types.string,
        branch: types.string,
        startDate: types.Date,
        endDate: types.Date,
        fileExist: types.boolean,
        currentPage: types.integer,
    })
    .views((self) => ({
        get List() {
            return values(self.list);
        },
        // get ExcelList() {
        //   return values(self.list).map(x => ({
        //     created:x.created,
        //     trainee: x.trainee.displayName,
        //     trainer: x.trainer.displayName,
        //     date: x.date,
        //     status: x.status,
        //     title: x.title,
        //     organization: x.organization
        //   }))
        // },
        get Pages() {
            return self.pages;
        },
        get Files() {
            return values(self.files);
        }
    }))
    .actions((self) => {
        return {
            fetch: flow(function* (page) {
                const rootStore = getParent(self, 1);
                let response, url;
                if (rootStore.profileStore.userType === "AHKKorea" || rootStore.profileStore.userType === "HRDK") {
                    url = `/recordbook-file/list?_page=${page}&_limit=24&FileCount__=1`
                    response = yield axios.get(url);
                } else if (rootStore.profileStore.userType === "BrandAdmin") {
                    url = `/recordbook-file/list?_page=${page}&_limit=24`
                    response = yield axios.get( url);
                }
                console.log("AdminRecordBookList url :",url);
                self.list = response.data.list;
                self.pages = response.data.pages;
            }),
            excelDownload: flow(function* () {

            }),
            fetchFiles: flow(function* (id) {
                const response = yield axios.get(
                    `/recordbook-file/files-by-recordbook/${id}`
                );
                console.log("fetchFilesByRecordBookId", id, response);
                // self.files = response.data;

                if (Array.isArray(response.data)) {
                    response.data.forEach(_ => self.files.put(_));
                } else {
                    console.error("fetchFilesByRecordBookId response data is not Array")
                }
            }),
            setStartDate: flow(function* (startDate) {
                self.startDate = startDate;
                yield self.search();
            }),
            setEndDate: flow(function* (endDate) {
                self.endDate = endDate;
                yield self.search();
            }),

            _search: flow(function* () {
                let queryString = `/recordbook-file/list?_page=${self.currentPage}&_limit=1`;
                if (self.traineeName) {
                    queryString += `&displayName_like=${self.traineeName}`
                }
                if (self.brand) {
                    queryString += `&brand_like=${self.brand}`
                }
                if (self.dealer) {
                    queryString += `&dealer_like=${self.dealer}`
                }
                if (self.branch) {
                    queryString += `&branch_like=${self.branch}`
                }
                //   if(self.startDate){
                queryString += `&date_gte=${dayjs(self.startDate).format('YYYY-MM-DD')}`
                //   }
                //   if(endDate){
                queryString += `&date_lte=${dayjs(self.endDate).add(1, 'd').format('YYYY-MM-DD')}`
                //   }

                console.log("queryString", queryString)

                // self.list.clear();
                const response = yield axios.get(queryString);
                console.log("search", response.data);

                if (isAlive(self.list)) {
                    self.list = response.data.list;
                }
                self.pages = response.data.pages;
            }),

            search: flow(function* (
                traineeName,
                brand,
                dealer,
                branch,
                startDate,
                endDate,
                fileExist,
                page,
                csv
            ) {

                //   let queryString = `/recordbook-file/list?_page=${page}&_limit=24`;
                let queryString = `/recordbook-file/list?_limit=30`;
                if (traineeName) {
                    queryString += `&displayName_like=${traineeName}`
                }
                if (brand) {
                    queryString += `&brand_like=${brand}`
                }
                if (dealer) {
                    queryString += `&dealer_like=${dealer}`
                }
                if (branch) {
                    queryString += `&branch_like=${branch}`
                }
                if (startDate) {
                    queryString += `&date_gte=${dayjs(startDate).format('YYYY-MM-DD')}`
                }
                if (endDate) {
                    queryString += `&date_lte=${dayjs(endDate).add(1, 'd').format('YYYY-MM-DD')}`
                }
                if (fileExist) {
                    queryString += `&fileCount_gte=1`
                }

                console.log("AdminRecordBookList search queryString", queryString);
                if (csv) {
                    console.log("csv download", queryString)
                    queryString += `&_csv=true`
                    const response = yield axios.get(queryString, {
                        responseType: "blob", // important
                    });

                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "Report.csv"); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                } else {
                    queryString += `&_page=${page}`
                    const response = yield axios.get(queryString);
                    // self.list.clear();
                    self.list.clear();
                    console.log("AdminRecordBookList search list, pages", response.data.list, response.data.pages);
                    console.log("isAlive", isAlive(self));

                    // self.list = [...response.data.list];
                    // response.data.list.forEach(r => self.list.put(r))
                    // self.list.put = map();
                    self.pages = response.data.pages;
                    const totalCount = response.headers["X-Total-Count"];
                    console.log("total count", totalCount);
                    return response.data.list
                }
                console.log("queryString", queryString)

            }),
        };
    });

export default AdminRecordBookListStore;
