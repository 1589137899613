import { types, flow, applySnapshot } from "mobx-state-tree";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';


const SERVER_BASE = `${process.env.API_SERVER}/auth`;

export const AuthStoreInitial = {
  email: "",
  password: "",
};

export const AuthStore = types
  .model("AuthStore", {
    email: types.string,
    password: types.string,
    error: false,
    errMessage: "",
    success: false,
    isLoading: false,
  })
  .actions((self) => {
    return {
      clearError: () => {
        self.errMessage = "";
        self.error = false;
      },
      setError: (error) => {
        self.success = false;
        self.errMessage = error;
        self.error = true;
      },
      setSuccess: (val) => {
        self.success = val;
      },
      login: flow(function* (values) { //  Auth hook 사용함
        applySnapshot(self, values);
        console.log("login AuthStore.self", self);
        const deviceId = sessionStorage.getItem("deviceId");
        self.isLoading = true;
        yield axios
          .post(SERVER_BASE + "/token", {
            email: self.email,
            password: self.password,
            deviceId: deviceId ? deviceId : uuidv4()
          })
          .then(res => {
            console.log('login then res.data', res.data)
            sessionStorage.setItem("tokens", JSON.stringify(res.data.tokens));

            // self.setSuccess(true);
            return true;
          })
          .catch((error) => {
            if (error.response) {
              console.log('error.response', error.response)
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              // self.setSuccess(false);
              self.setError(error.response.data);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
            }
            console.log(error.config);
            return false;
          })
          .finally(() => {
            self.isLoading = false;
          })
          ;
        // self.activationSuccess = true;
      }),
    };
  });
