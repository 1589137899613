import { types, flow } from "mobx-state-tree";
import axios from "../../../store/axios";
import DateTime from "../../../store/DateTime";

export const TtTApplyModalStoreInitial = {
  location:"",
  date: null,
  state: null,
};

export const TtTApplyModalStore = types
  .model("TtTApplyModalStore", {
    location: types.string,
    date:types.maybeNull( DateTime),
    state: types.maybeNull(types.string),
  })
  .actions((self) => {
    return {
      edit(name, value) {
        self[name] = value;
      },
      saveTtTApply: flow(function*({templateId, signature}) {
        try{
        yield axios
          .post(`/form/ttt-apply/${templateId}`, {signature, location:self.location, date:self.date} );
          self.state = "success";
        } catch(err){
          self.state = "error";
        }
      }),
      clearState() {
        self.date = null;
        self.location = "";
        self.state = null;
      },
    };
  });

export default TtTApplyModalStore;
