import DateTime from "./DateTime";
import { types, flow,destroy, getParent } from "mobx-state-tree";
import axios from "./axios";
import { values } from "mobx";

export const FormTaskStoreInitial = {
  list : []
};

export const FormTaskEntry  = types.model("FormTaskEntry",
{
  id: types.identifierNumber,
  title: types.string,
  description:  types.string,
  confirmed: types.boolean,
  submitted: types.boolean,
  templateType: types.enumeration(["AusbildungAgreement","PrivacyAgreement","LaborContract","TtTApply","NDA", "ParentAgreement", "TraineeTest", "TtTTest", "SignUpTrainee"]),
  fileName : types.maybeNull(types.string),
}
)
.actions((self) => {
  return {
    saveSignature: flow(function*({templateId, signature}) {
      yield axios
        .post(`/form/signature/${templateId}`, {signature} );
    }),
    changeSubmitted(){
      self.submitted = true;
    }
  }
})

export const TraineeTestApplyEntry = types.model("TraineeTestApplyEntry",{
    id: types.identifierNumber,
    traineeName: types.string,
    trainerConfirmed: types.maybeNull(DateTime),
    testName: types.integer,
})

export const FormTaskStore = types.model("FormTaskStore", {
  list: types.array(FormTaskEntry),
  traineeTestApplies: types.array(TraineeTestApplyEntry)
} )
.views(self => ({
  get List() {
  return values(self.list);
  },
  get NotSubmittedCount() {
    return values(self.list).filter(x => !x.submitted).length;
  },
  get TraineeTestApplies() {
      return values(self.traineeTestApplies);
  }
}))
.actions((self) => {
  return {
    fetch: flow(function* (){
      const result = yield axios.get(`form/templates`);
      console.log("FormTask.store result", result.data);
      self.list = result.data;
    }),
    remove(item){
      destroy(item);
    }
    ,
    fetchTraineeTestApplies: flow (function* (){
      const result = yield axios.get(`form/trainee-test-applies`);
      console.log("FormTask.store form/trainee-test-applies", result.data);
      self.traineeTestApplies = result.data;
    })
  }
})
