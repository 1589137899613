import { types, flow, getParent } from "mobx-state-tree";
import _ from "lodash";
import mockData from "./__mocks__/TraineeRecordBookStatus.store.data";
import {
  CategoryModel,
  SemesterModel,
  SubjectModel,
} from "../../store/Course.store";
import axios from "../../store/axios";
import { values } from "mobx";
import DateTime from "../../store/DateTime";

export const TraineeRecordBookStatusStoreInitial = {
  categories: {},
  recordBooks : {},
};

const TraineesState = types
  .model({
    assignedDays: types.optional(types.integer, 0), //subject에 할당된 일자수
  })
//   .actions((self) => {
//       return {
//       decreaseRequestedCount () {
//         const rb = root.RecordBooks.filter((_) => 
//             _.subjectId === self.id 
//             && _.confirmState === "확인요청");
//         console.log("decreaseRequestedCount", rb);
//           rb.confirmState == "확인완료"
//       }
//   )
  .views((self) => ({
    get recordBookStates() {
      // console.log("recordBookStates getParent(self, 6)", getParent(self, 6));
      const root = getParent(self, 6);
      const confirmedCount = root.RecordBooks.filter((_) =>
        _.subjectId === self.id
        &&
        _.confirmState === "확인완료"
      ).length;

      // console.log("recordBookStates confirmed list ", root.RecordBooks.filter((_) => _.subjectId === self.id && _.confirmState === "확인요청"));

      const requestedCount = root.RecordBooks.filter((_) =>
      _.subjectId === self.id
      &&
      _.confirmState === "확인요청"
    ).length

      return {
        ratio:
          confirmedCount > self.assignedDays ? 100 : (confirmedCount / self.assignedDays) * 100,
        count: `${confirmedCount}/${self.assignedDays}`,
        requestedCount,
        confirmedCount
      };
    },
    
  }));

const SubjectEntry = types
  .compose(SubjectModel, TraineesState)
  .named("SubjectEntry");

const SubjectStateCalculator = types
  .model({
    subjects: types.array(SubjectEntry),
  })
  .views((self) => ({
    get state() {
      const completeSubjectCount = self.subjects.reduce(
        (sum, sub) => sum + (sub.recordBookStates.ratio >= 100 ? 1 : 0), 0,
        0
      );
      const confirmedCountInCategory = self.subjects.reduce(
        (sum, sub) => sum + sub.recordBookStates.confirmedCount , 0,
      );

      return {
        completeSubjectCount,
        subjectCount: self.subjects.length,
        // isComplete: (completeSubjectCount / self.subjects.length) >= 1 ? ((confirmedCountInCategory >= self.requiredDays) & (self.IsAllAssigned)) : (confirmedCountInCategory >= self.requiredDays) ,
        isComplete: (completeSubjectCount / self.subjects.length) >= 1 ? ( self.IsAllConfirmed) : (confirmedCountInCategory >= self.requiredDays) , //각각의 모든 주제에 대해서 Confirm이 존재 하는지
        ratio: (confirmedCountInCategory / self.requiredDays) >= 1 ? 100 : Math.round((confirmedCountInCategory / self.requiredDays) * 100),
        confirmedCount:confirmedCountInCategory,
        subjectRatio: (( self.requiredDays < self.subjects.length) && (confirmedCountInCategory >= self.requiredDays)) ? 100 : Math.round((completeSubjectCount / self.subjects.length) * 100),
      };
    },

    get IsAllAssigned() {
      const allSubjectAssigned = self.subjects.reduce(
        (bResult, sub) => bResult & (sub.assignedDays > 0 ? true : false), true
      )
      return  allSubjectAssigned;
    }
    ,
    get IsAllConfirmed() {
      return self.subjects.reduce(
        (bResult, sub) => bResult & (sub.recordBookStates.confirmedCount > 0 ? true : false), true
      );
    }

  }));

const SemesterEntry = types
  .compose(SemesterModel, SubjectStateCalculator)
  .named("SemesterEntry");

// const TraineeEntry = types.model("TraineeEntry", {
//   id: types.identifierNumber,
//   name: types.string,
// });

export const CategoryEntry = types
  .compose(
    CategoryModel,
    types.model({
      semesters: types.array(SemesterEntry),
    })
  )
  .named("CategoryEntry");
//confirmed record books
export const RecordBookEntry = types.model("RecordBookEntry", {
  subjectId: types.integer,
  id: types.identifierNumber,
  semesterType: types.enumeration("semesterType", ["가", "나", "전체"]),
  date: DateTime,
  confirmState: types.enumeration("confirmState",["확인요청", "확인완료"])
}).actions((self) => {
    return{
        setConfirmState(state){
            console.log("setConfirmState", state);
            self.confirmState = state; 
        }
    }
});

export const TraineeRecordBookStatusStore = types
  .model("TraineeRecordBookStatusStore", {
    categories: types.map(CategoryEntry),
    recordBooks: types.map(RecordBookEntry),
  })
  .actions((self) => {
    return {
      setRecordbookState(recordBookId, state){
        console.log("setRecordbookState", recordBookId, state);
        self.recordBooks.find(book => book.id === recordBookId)?.setConfirmState(state);
      },
      fetch: flow(function* (traineeId) {
        self.categories.clear();
        self.recordBooks.clear();
        
        let response;
        const profileStore = getParent(self, 1).profileStore
        if(profileStore.userType !== "Trainee"){
          response = yield axios.get(`/record-book/trainee/${traineeId}`);
        }else{
          response = yield axios.get(`/record-book/trainee`);
        }
        // console.log(`TraineeRecordBook : ${response.data.categories}`);
        // const response = yield axios.get("/record-book/trainee");
        response.data.categories.forEach((c) => self.categories.put(c));
        response.data.recordBooks.forEach((r) => self.recordBooks.put(r));
      }),

      getTestability: (semester) => {
        const semesterRecordBooks = self.RecordBooks.filter(
          (r) => r.semesterType === semester && r.confirmState === "확인완료"
        );
        const confirmedRecordBookCount = semesterRecordBooks.length;
        // const allSemesterRequiredDayCount = self.Categories.reduce(
        const allSemesterRequiredDayCount = self.Categories.reduce(
          (count, cats) =>
            count +
            cats.semesters
              .filter((s) => s.semesterType === semester)
              .reduce((sum, sem) => sum + sem.requiredDays, 0),
          0
        );

        // const subjectFullCount = self.Categories.reduce(
        //   (count, cats) =>
        //     count +
        //     cats.semesters
        //       .filter((s) => s.semesterType === semester )
        //       .reduce((sum, sem) => sum + sem.subjects.filter(s => s.recordBookStates.ratio >= 100).length, 0),
        //   0
        // );
        // const subjectAllCount = self.Categories.reduce(
        //   (count, cats) =>
        //     count +
        //     cats.semesters
        //       .filter((s) => s.semesterType === semester )
        //       .reduce((sum, sem) => sum + sem.subjects.length, 0),
        //   0
        // );

        const allSemesterCount = self.Categories.reduce(
          (count, cats) =>
          count +
          cats.semesters
            .filter((s) => s.semesterType === semester )
            .reduce((sum, sem) => sum + 1, 0),
          0
        );

        const completeSemesterCount = self.Categories.reduce(
          (count, cats) =>
          count +
          cats.semesters
            .filter((s) => s.semesterType === semester )
            .reduce((sum, sem) => sum + sem.state.isComplete, 0),
            0
        );
          // console.log(`completeSemesterCount`, completeSemesterCount);
        const ratio =
          confirmedRecordBookCount >= allSemesterRequiredDayCount
            ? 100
            : Math.round((confirmedRecordBookCount / allSemesterRequiredDayCount) * 100);
        // const ratio = Math.round((completeSemesterCount / allSemesterCount) * 100);

        const months = [
          ...new Set(
            semesterRecordBooks.map(
              ({ date: d }) => `${d.year()}.${d.month() + 1}`
            )
          ),
        ];

        // const uniqueMonths = semesterRecordBooks.sort((s)=> s.date).reduce((sum, mo) => ,[])
        const monthCount = months.map((mo) => ({
          month: mo,
          count: semesterRecordBooks.filter(
            (r) => `${r.date.year()}.${r.date.month() + 1}` === mo
          ).length,
        }));

        return {
        //   ratio: 100,
          ratio,
          completeSemesterCount,
          allSemesterCount,
          recordBookCount: confirmedRecordBookCount,
          semesterRequiredDays: allSemesterRequiredDayCount,
          months: monthCount.map((mo) => mo.month),
          counts: monthCount.map((mo) => mo.count),
          categoryRatio: Math.round((completeSemesterCount/allSemesterCount) * 100)
        //   categoryRatio: 90,
        };
      },
    };
  })
  .views((self) => ({
    get Categories() {
      return values(self.categories);
    },
    get RecordBooks() {
      return values(self.recordBooks);
    },
    get FirstTest() {
      return self.getTestability("가");
    },
    get SecondTest() {
      return self.getTestability("나");
    },
    get AllTime() {
      const firstSemester = self.getTestability("가");
      const secondSemester = self.getTestability("나");
      const allSemester = self.getTestability("전체");
      return [firstSemester, secondSemester, allSemester].reduce(
        (sum, semester) => {
          sum.recordBookCount += semester.recordBookCount;
          sum.semesterRequiredDays += semester.semesterRequiredDays;
          return sum;
        },
        { recordBookCount: 0, semesterRequiredDays: 0 }
      );
    },
  }));

export default TraineeRecordBookStatusStore;
