import { types } from "mobx-state-tree";
// import moment from "moment";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)


const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
export default  types.custom({
  name: "DateTime",
  fromSnapshot: s => dayjs.utc(s+"Z", DATE_FORMAT).local(),
  toSnapshot: (mDate) => mDate.format(DATE_FORMAT),
  isTargetType: maybeMoment => dayjs.isDayjs(maybeMoment),
  getValidationMessage: snapshot => {
    // console.log('DateTime:getValidationMessage snapshot', snapshot)
    if (snapshot === undefined || snapshot === null) return "";
    const mDate = dayjs(snapshot, DATE_FORMAT, true);
    if (!mDate.isValid()) {
      const message = `"${snapshot}" is not in valid date format ${DATE_FORMAT}`;
      console.error(message);
      return message;
    }
    return "";
  }
});
