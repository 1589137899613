import { values } from "mobx";
import { types, flow, destroy, getParent } from "mobx-state-tree";
import { FieldModel } from "src/store/ListField.model";
import axios from "../../../store/axios";
import DateTime from "../../../store/DateTime";

export const TraineeVacationStoreInitial = {
  description: "",
  selectedDate: null,
  vacations: [],
  fields: [
    { key: "date", label: "날짜", _style: {width:"30%"}},
    { key: "description", label: "사유" , _style: {width:"60%"}},
    { key: "id", label: "삭제", _style: {width:"10%"}},
  ],
  state: "",
};

export const VacationModel = types.model("VacationModel", {
  id: types.identifierNumber,
  date: DateTime,
  description:  types.maybeNull(types.string),
});

export const TraineeVacationStore = types
  .model("TraineeVacationStore", {
    description: types.string,
    selectedDate: types.maybeNull(DateTime),
    vacations: types.array(VacationModel),
    state: types.string,
    fields: types.array(FieldModel),
  })
  .views((self) => ({
    get Vacations(){
      return values(self.vacations);
    },
    get Fields() {
      return values(self.fields);
    }
  }))
  .actions((self) => {
    return {
      edit(name, value) {
        self[name] = value;
      },
      fetch: flow(function* (traineeId, semester) {
        const result = yield axios.get(`/member/trainee-vacation/${traineeId}/${semester}`);
        self.vacations = result.data;
        console.log(`self.vacations:${self.vacations}`);
        return result.data;
      }),

      clearState() {
        self.state = "";
      },
    
      clearAllState: flow(function* (traineeId){
        self.selectedDate = null;
        self.state = "";
        self.description = "";
        self.vacations = [];
        const traineeRecordBookStatusStore = getParent(self,1).traineeRecordBookStatusStore;
        yield traineeRecordBookStatusStore.fetch(traineeId);
      }),

      delete: flow(function* (item) {
        yield axios.delete(`/member/trainee-vacation/${item.id}`);
        destroy(item);
        const traineeRecordBookStatusStore = getParent(self,1).traineeRecordBookStatusStore;
        yield traineeRecordBookStatusStore.fetch(item.id);
      }),

      save: flow(function* (traineeId, semester) {
        const body = {
            selectedDate: self.selectedDate,
            description: self.description,
            traineeId,
            semesterType: semester
          };
          console.log("TraineeVacation store save", body);
          let created;
        try {
          created = yield axios.post(`/member/trainee-vacation`, body );
          self.state = "success";
        } catch (err) {
          self.state = "error";
        }
        self.vacations.push({id:created.data, description: self.description, date: self.selectedDate});
        const result = yield axios.get(`/member/trainee-vacation/${traineeId}/${semester}`);
        self.vacations = result.data;

        // const traineeRecordBookStatusStore = getParent(self,1).traineeRecordBookStatusStore;
        // yield traineeRecordBookStatusStore.fetch(traineeId);
      }),
    };
  });

export default TraineeVacationStore;
