import { getParent, types, flow } from "mobx-state-tree";
import { values } from "mobx";
import dayjs from "dayjs";
import axios from "../../store/axios";
import DateTime from '../../store/DateTime'

export const NoticeCalendarStoreInitial = {
  events: {},
};

const NoticeEventEntry = types.model("NoticeEventEntry", {
  id: types.identifierNumber,
  start: DateTime,
  end: DateTime,
  title: types.string,
});

export const NoticeCalendarStore = types
  .model("NoticeCalendarStore", {
    events: types.optional(types.map(NoticeEventEntry), {}),
  })
  .views((self) => ({
    get Events() {
      return values(self.events);
    },
  }))
  .actions((self) => {
    return {
      setPrevMonth: flow(function* () {
        const prevStart = dayjs(self.start)
          .diff(-1, "month")
          .format("YYYY-MM-DD");
        const prevEnd = dayjs(self.end).diff(-1, "month").format("YYYY-MM-DD");
        yield self.fetch(prevStart, prevEnd);
      }),

      getThisMonth: flow(function* () {
        const currDate = new Date();
        const currYear = currDate.getFullYear();
        const currMonth = currDate.getMonth();
        yield self.fetch(currYear, currMonth);
      }),

      fetch: flow(function* (year, month) {
        const res = yield axios.get(`/notice/calendar/${year}/${month}`);
        const notices = res.data;

        notices.forEach((notice) => {
          self.events.put(notice);
        });
      }),
    };
  });

export default NoticeCalendarStore;
