import { getParent, types, flow, clone } from "mobx-state-tree";
import { values } from "mobx";
import dayjs from "dayjs";
import axios from "../../store/axios";
import DateTime from "../../store/DateTime";
import { FieldModel } from "../../store/ListField.model";
import cloneDeep from "lodash.clonedeep";
// import { custom } from "mobx-state-tree/dist/internal";


export const AdminLessonListStoreInitial = {
  list: [],
  fields: [
    { key: "trainer", label: "트레이너" },
    { key: "trainee", label: "트레이니" },
    { key: "organization", label: "소속" },
    { key: "start", label: "일자" },
    { key: "title", label: "제목" },
    { key: "subjectIndex", label: "Subject Index" },
  ],
};

const IdNameEntry = types.model("IdNameEntry", {
  id: types.integer,
  displayName: types.string,
});

const AdminLessonListEntry = types.model("AdminLessonListEntry", {
  id: types.identifierNumber,
  created: DateTime,
  title: types.string,
  trainee: IdNameEntry,
  trainer: IdNameEntry,
  organization: types.string,
  subjectIndex: types.string,
});

export const AdminLessonListStore = types
  .model("AdminLessonListStore", {
    list: types.array(AdminLessonListEntry),
    fields: types.array(FieldModel),
  })
  .views((self) => ({
    get List() {
      return values(self.list);
    },
    get ExcelData(){
      const data = [];
      const list = values(self.list);
      for (let i = 0; i < list.length; i++)
      {
        data.push({subjectIndex:list[i].subjectIndex, id : list[i].id, title : list[i].title, trainer: list[i].trainer.displayName, trainee: list[i].trainee.displayName, organization: list[i].organization, created: dayjs(list[i].created).format('YYYY -MM-DD')})
      }
      return data;
    },

    get Pages() {
      return self.pages
    }

  }))
  .actions((self) => {
    return {
      fetch: flow(function* (page) {
        const rootStore = getParent(self, 1);
        let response;
        if (rootStore.profileStore.userType === "AHKKorea") {
          response = yield axios.get(`/lesson/list-all?page=${page}&limit=24`);
        } else if (rootStore.profileStore.userType === "BrandAdmin") {
          response = yield axios.get(
            `/lesson/list-brand?page=${page}&limit=24`
          );
        }
        console.log(response.data);
        self.list = response.data.list;
        self.pages = response.data.pages;
      }),

      fetchExcel: flow(function* (){
        const rootStore = getParent(self, 1);
        let response;
        if (rootStore.profileStore.userType === "AHKKorea") {
          response = yield axios.get(`/lesson/list-all-excel`);
        } else if (rootStore.profileStore.userType === "BrandAdmin") {
          response = yield axios.get(
            `/lesson/list-brand-excel`
          );
        }
        console.log(response.data);
        self.listExcel = response.data;
      })
    };
  });

export default AdminLessonListStore;
