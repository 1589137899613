import { getParent, types, flow, clone, applySnapshot } from "mobx-state-tree";
import { values } from "mobx";
import axios from "../../store/axios";
import dayjs from "dayjs";
import DateTime from "src/store/DateTime";
import FileStore from "../../store/FileStore";

export const DashboardStoreInitial = {
  // dealerTraineeCount:[ {
  //   dealer:"",
  //   trainees:[]
  // }],

  trainerCertificateStatus: {
    completed: 0,
    unCompleted: 0,
  },

  trainerCountByDealer:[],

  traineeCountByEnterYear: {
    total: 0,
    perYear: [
      {
        enterYear: 2000,
        count: 0,
      },
    ],
  },

  traineeCountByStatus: [
    {
      status: "On",
      count: 0,
    },
  ],

  trainerCount: {
    total: 0,
  },

  dropCount: {
    drops: [],

    yearlyTrainees: [],
  },

  dealerCount: {
    dealers: 0,
    branches: 0,
  },

  traineeTestStatus: {
    firstWaiting: 0,
    secondWaiting: 0,
    pass: 0,
  },
  // traineeCountStatusTable:[{
  //   enterYear: 0,
  //   counts: [],
  // }]
};

export const YearCountEntry = types.model("YearCountEntry", {
  enterYear: types.number,
  count: types.number,
});

export const TraineeCountByEnterYear = types.model("TraineeCountByEnterYear", {
  total: types.number,
  perYear: types.array(YearCountEntry),
});

export const TrainerCount = types.model("TrainerCount", {
  total: types.number,
});

export const DropCount = types
  .model("DropCount", {
    drops: types.array(YearCountEntry),
    yearlyTrainees: types.array(YearCountEntry),
  })
  .views((self) => ({
    get DropTotal() {
      return (
        self.drops.length > 0 && self.drops.reduce((a, b) => a + b.count, 0)
      );
    },
    get TraineeTotal() {
      return (
        self.yearlyTrainees.length > 0 &&
        self.yearlyTrainees.reduce((a, b) => a + b.count, 0)
      );
    },
    get DropRatio() {
      return (
        self.TraineeTotal > 0 &&
        ((self.DropTotal / self.TraineeTotal) * 100).toFixed(1)
      );
    },
    get YearlyRatio() {
      return self.drops.map((drop) => ({
        drop: drop.count,
        total: self.yearlyTrainees.find((_) => _.enterYear === drop.enterYear)
          .count,
      }));
    },
  }));

export const DealerCount = types.model("DealerCount", {
  dealers: types.number,
  branches: types.number,
});

export const TraineeCountByStatus = types.model("TraineeCountByStatus", {
  status: types.maybeNull (types.enumeration("TraineeStatus", [
       'On',
        'Military',
        'Drop',
        'Completed',
        'NotCertified',
  ])),
  count: types.number,
});

export const TraineeTestStatus = types.model("TraineeTestStatus", {
  firstWaiting: types.number,
  secondWaiting: types.number,
  pass: types.number,
});

export const TraineeCountStatusData = types.model("TraineeCountStatusData", {
  enterYear: types.identifierNumber,
  counts: types.array(types.number),
});

function getPivotArray(dataArray, rowIndex, colIndex, dataIndex) {
  //Code from https://techbrij.com
  var result = {},
    ret = [];
  var newCols = [];
  for (var i = 0; i < dataArray.length; i++) {
    if (!result[dataArray[i][rowIndex]]) {
      result[dataArray[i][rowIndex]] = {};
    }
    result[dataArray[i][rowIndex]][dataArray[i][colIndex]] =
      dataArray[i][dataIndex];

    //To get column names
    if (newCols.indexOf(dataArray[i][colIndex]) == -1) {
      newCols.push(dataArray[i][colIndex]);
    }
  }

  newCols.sort();
  var item = [];

  //Add Header Row
  item.push("Item");
  item.push.apply(item, newCols);
  ret.push(item);

  //Add content
  for (var key in result) {
    item = [];
    item.push(key);
    for (var i = 0; i < newCols.length; i++) {
      item.push(result[key][newCols[i]] || "-");
    }
    ret.push(item);
  }
  return ret;
}

export const DealerCountEntry = types.model("DealerCountEntry", {
  dealer: types.string,
  count: types.number,
})

export const TrainerCountByDealer = types.model("TrainerCountByDealer", {
  dealers: types.array(DealerCountEntry),
})
.views((self) => ({
  get Total() {
    return self.dealers.sum(_ => _.count);
  }
})
)
;

export const TrainerCertificateStatus = types.model("TrainerCertificateStatus", {
  completed: types.number,
  unCompleted: types.number,
});

export const DealerBranchCountEntry = types.model("DealerBranchCountEntry", {
  dealer: types.string,
  branches: types.number,
})
;

export const TraineeEnterYearCountEntry = types.model("TraineeEnterYearCountEntry", {
  enterYear: types.number,
  count: types.number,
})
;

export const DealerTraineeCountEntry = types.model("DealerTraineeCountEntry", {
  dealer: types.string,
  trainees: types.array(TraineeEnterYearCountEntry),
})
.views((self) => ({
  get Trainees() {
    return values(self.trainees);
  }
}))
;

export const TraineeCountStatusEntry = types.model("TraineeCountStatusEntry",{
  status: types.maybeNull(types.string),
  count: types.number,
})

export const TraineeCountStatusTableEntry = types.model("TraineeCountStatusTableEntry", {
  enterYear: types.number,
  countsByStatus: types.array(TraineeCountStatusEntry),
})

export const DashboardStore = types
  .model("DashboardStore", {
    traineeCountByEnterYear: TraineeCountByEnterYear,
    traineeCountByStatus: types.array(TraineeCountByStatus),
    trainerCount: TrainerCount,
    dropCount: DropCount,
    dealerCount: DealerCount,
    traineeTestStatus: TraineeTestStatus,
    traineeCountStatusTable: types.array(TraineeCountStatusTableEntry), //,
    trainerCountByDealer: types.array(DealerCountEntry),
    trainerCertificateStatus: TrainerCertificateStatus,
    dealerBranchCount: types.array(DealerBranchCountEntry),
    dealerTraineeCount: types.array(DealerTraineeCountEntry),
    isLoading: false,
  })
  .views((self) => ({
    get TotalBranchCount(){
     return self.dealerBranchCount.reduce( (a,b) => a + b.branches, 0);
    },
    get TotalDealerCount(){
     return self.trainerCountByDealer.reduce( (a,b) => a + b.count, 0);
    },
    get TraineeEnterYears() {
      return self.traineeEnterYears;
    },
    get RecentYears() {
      const currYear = dayjs().year();
      const result = [];
      for (let i = 0; i < 5; i++) {
        result.push(currYear - i);
      }
      return result;
    },
    get TraineeCountStatusTable() {
      const on = [];
      const off = [];
      const military = [];
      const drop = [];
      if(self.traineeCountStatusTable){
        values(self.traineeCountStatusTable)?.forEach((_) => {
         on.push(values(_.countsByStatus).find(_ => _.status === "On").count);
         off.push(values(_.countsByStatus).find(_ => _.status === "Off").count);
         military.push(values(_.countsByStatus).find(_ => _.status === "Military").count);
         drop.push(values(_.countsByStatus).find(_ => _.status === "Drop").count);
        });
      }


        // [
        //   {title:"OJT", count:_.countsByStatus.find(_ => _.status === "On").count},
        //   {title:"OFF-JT", count:_.countsByStatus.find(_ => _.status === "Off").count},
        //   {title:"군복무", count:_.countsByStatus.find(_ => _.status === "Military").count},
        //   {title:"중도탈락", count:_.countsByStatus.find(_ => _.status === "Drop").count}
        // ] );
      return [{title: "OJT", data:on}, {title:"OFF-JT", data:off}, {title:"군복무", data:military}, {title:"중도포기", data: drop}];
    }
  }))
  .actions((self) => {
    return {
      fetch: flow(function* () {
        const rootStore = getParent(self, 1);
        rootStore.setIsLoading(true);
        // self.isLoading = true;
        const dealerCountByDealerResult = yield axios.get(
          `dashboard/brand/dealer-trainee-by-enter-year`
        );
        self.dealerTraineeCount = dealerCountByDealerResult.data.dealerCount;
        self.traineeEnterYears = dealerCountByDealerResult.data.years;

        const dealerBranchCountResult = yield axios.get(
          `dashboard/brand/branch-count-by-dealer`
        );
        self.dealerBranchCount = dealerBranchCountResult.data;

        const trainerCountByDealerResult = yield axios.get(
          `dashboard/brand/trainer-count-by-dealer`
        );
        self.trainerCountByDealer = trainerCountByDealerResult.data;

        const response = yield axios.get(
          `dashboard/brand/trainee-status-count-table`
        );
        self.traineeCountStatusTable = response.data.countsByStatus;
        self.traineeEnterYears = response.data.years;
        // self.traineeStatusTableData = getPivotArray(result, 0, 1, 2);
        const traineeTestStatusResult = yield axios.get(
          `dashboard/brand/trainee-test-status`
        );
        self.traineeTestStatus = traineeTestStatusResult.data;

        const traineeCountByStatusResult = yield axios.get(
          `dashboard/brand/trainee-count-by-status`
        );
        self.traineeCountByStatus = traineeCountByStatusResult.data;

        const traineeCountByEnterYearResult = yield axios.get(
          `dashboard/brand/trainee-count-by-enter-year`
        );
        self.traineeCountByEnterYear = traineeCountByEnterYearResult.data;

        const trainerCountResult = yield axios.get(
          `dashboard/brand/trainer-count`
        );
        self.trainerCount = trainerCountResult.data;

        const traineeDropResult = yield axios.get(`dashboard/brand/drops`);
        self.dropCount = traineeDropResult.data;

        const dealerCountResult = yield axios.get(
          `dashboard/brand/dealer-count`
        );
        self.dealerCount = dealerCountResult.data;

        rootStore.setIsLoading(false);

      }),
      fetchDealerCountByDealer: flow(function* () {
        const dealerCountByDealerResult = yield axios.get(
          `dashboard/brand/dealer-trainee-by-enter-year`
        );
        self.dealerTraineeCount = dealerCountByDealerResult.data.dealerCount;
        self.traineeEnterYears = dealerCountByDealerResult.data.years;
      }),
      fetchBranchCountByDealer: flow(function* () {
        const dealerBranchCountResult = yield axios.get(
          `dashboard/brand/branch-count-by-dealer`
        );
        self.dealerBranchCount = dealerBranchCountResult.data;
      }),
      fetchTrainerCertificateStatus: flow(function* () {
        const trainerCertificateStatusResult = yield axios.get(
          `dashboard/brand/trainer-certificate-status`
        );
        self.trainerCertificateStatus = trainerCertificateStatusResult.data;
      }),
      fetchTrainerCountByDealer: flow(function* () {
        const trainerCountByDealerResult = yield axios.get(
          `dashboard/brand/trainer-count-by-dealer`
        );
        self.trainerCountByDealer = trainerCountByDealerResult.data;
      }),
      fetchTraineeStatusTable: flow(function* () {
        const response = yield axios.get(
          `dashboard/brand/trainee-status-count-table`
        );
        self.traineeCountStatusTable = response.data.countsByStatus;
        self.traineeEnterYears = response.data.years;
        // self.traineeStatusTableData = getPivotArray(result, 0, 1, 2);
      }),
      fetchTraineeTestStatus: flow(function* () {
        const traineeTestStatusResult = yield axios.get(
          `dashboard/brand/trainee-test-status`
        );
        self.traineeTestStatus = traineeTestStatusResult.data;
      }),
      fetchTraineeCountByStatus: flow(function* () {
        const traineeCountByStatusResult = yield axios.get(
          `dashboard/brand/trainee-count-by-status`
        );
        self.traineeCountByStatus = traineeCountByStatusResult.data;
      }),
      fetchTraineeCountByEnterYear: flow(function* () {
        const traineeCountByEnterYearResult = yield axios.get(
          `dashboard/brand/trainee-count-by-enter-year`
        );
        self.traineeCountByEnterYear = traineeCountByEnterYearResult.data;
      }),
      fetchTrainerCount: flow(function* () {
        const trainerCountResult = yield axios.get(
          `dashboard/brand/trainer-count`
        );
        self.trainerCount = trainerCountResult.data;
      }),
      fetchTraineeDropCount: flow(function* () {
        const traineeDropResult = yield axios.get(`dashboard/brand/drops`);
        self.dropCount = traineeDropResult.data;
      }),
      fetchDealerCount: flow(function* () {
        const dealerCountResult = yield axios.get(
          `dashboard/brand/dealer-count`
        );
        self.dealerCount = dealerCountResult.data;
      }),
      // fetch: flow(function* () {
      //   yield self.fetchDealerCountByDealer();
      //   yield self.fetchBranchCountByDealer();
      //   yield self.fetchTrainerCertificateStatus();
      //   yield self.fetchTrainerCountByDealer();
      //   yield self.fetchTraineeStatusTable();
      //   yield self.fetchTraineeTestStatus();
      //   yield self.fetchTraineeCountByStatus();
      //   yield self.fetchTraineeCountByEnterYear();
      //   yield self.fetchTrainerCount();
      //   yield self.fetchTraineeDropCount();
      //   yield self.fetchDealerCount();
      // }),
    };
  });

export default DashboardStore;
