import { useContext, createContext } from "react";
import { types } from "mobx-state-tree";
import { 
    ToasterStore, 
    ToasterStoreInitial 
} from "src/store/Toaster.store";
import { 
    AdminTransferRequestStore, 
    AdminTransferRequestStoreInitial 
} from "src/views/admin_trainsfer_request/AdminTransferRequest.store";
import {
    TrainerLessonPlanStore,
    TrainerLessonPlanStoreInitial,
} from "../views/trainer_lesson_plan/TrainerLessonPlan.store";
import {
    TraineeCalendarStore,
    TraineeCalendarStoreInitial,
} from "../views/trainee_calendar/TraineeCalendar.store";
import { AuthStore, AuthStoreInitial } from "./Auth.store";
import {
    TraineeRecordBookStatusStore,
    TraineeRecordBookStatusStoreInitial,
} from "../views/trainee_recordbook_status/TraineeRecordBookStatus.store";
// import { MyRecordBookStatusStore, MyRecordBookStatusStoreInitial } from '../views/my_recordbook_status/MyRecordBookStatus.store';
import {
    CreateRecordBookStore,
    CreateRecordBookStoreInitial,
} from "../views/trainee_calendar/CreateRecordBook.store";
import {
    RecordBookStore,
    RecordBookStoreInitial,
} from "../views/recordbook/RecordBook.store";
import { UserInfoStore, UserInfoStoreInitial } from "./UserInfo.store";
import {
    LessonListStore,
    LessonListStoreInitial,
} from "../views/lesson/LessonList.store";
import {
    ConfirmRecordBookStore,
    ConfirmRecordBookStoreInitial,
} from "../views/confirm_record_book/ConfirmRecordBook.store";
import { NoticeStore, NoticeStoreInitial } from "../views/notice/notice.store";
import {
    NoticeCalendarStore,
    NoticeCalendarStoreInitial,
} from "../views/notice_calendar/NoticeCalendar.store";
import { AccountStore } from "./Account.store";
import {
    LaborContractFormStore,
    LaborContractFormStoreInitial,
} from "../views/forms/laborContract/LaborContractForm.store";
import {
    DashboardStore,
    DashboardStoreInitial,
} from "../views/dashboard/dashboard.store";
import {
    MessageBoxStore,
    MessageBoxStoreInitial,
} from "../store/MessageBox.store";
import { FormTaskStore, FormTaskStoreInitial } from "../store/FormTask.store";
import { ProfileStore, ProfileStoreInitial } from "./Profile.store";
import {
    UploadModalStore,
    UploadModalStoreInitial,
} from "../views/forms/UploadModal.store";
import {
    ViewUploadModalStore,
    ViewUploadModalStoreInitial,
} from "../views/forms/ViewUploadModal.store";
import {
    TrainerProfileStore,
    TrainerProfileStoreInitial,
} from "../views/trainer_profile/TrainerProfile.store";
import {
    TraineeProfileStore,
    TraineeProfileStoreInitial,
} from "../views/trainee_profile/TraineeProfile.store";
import {
    TtTApplyModalStore,
    TtTApplyModalStoreInitial,
} from "../views/forms/TtTApply/TtTApply.store";
import {
    TraineeTestApplyStore,
    TraineeTestApplyStoreInitial,
} from "../views/forms/TraineeTestApply/TraineeTestApply.store";
import {
    TraineeTestApplyTrainerReviewStore,
    TraineeTestApplyTrainerReviewStoreInitial,
} from "../views/trainee_test_apply_trainer_review/TraineeTestApplyTrainerReview.store";
import {
    NoticeListStore,
    NoticeListStoreInitial,
} from "../views/notice_list/NoticeList.store";
import {
    AdminLessonListStore,
    AdminLessonListStoreInitial,
} from "../views/admin_lesson_list/AdminLessonList.store";
import {
    AdminRecordBookListStore,
    AdminRecordBookListStoreInitial,
} from "../views/admin_recordbook_list/AdminRecordBookList.store";
import {
    TraineeRecordBookListStore,
    TraineeRecordBookListStoreInitial,
} from "../views/trainee_recordbook_list/TraineeRecordBookList.store";
import {
    AdminTraineeListStore,
    AdminTraineeListStoreInitial,
} from "../views/admin_trainee_list/AdminTraineeList.store";
import {
    AdminTrainerListStore,
    AdminTrainerListStoreInitial,
} from "../views/admin_trainer_list/AdminTrainerList.store";
import {
    ImportRecordBookStore,
    ImportRecordBookStoreInitial,
} from "../views/import_recordbook/ImportRecordBook.store";
import {
    TransferRequestStore,
    TransferRequestStoreInitial,
} from "../views/transfer_request/TransferRequest.store";
import { NavStore, NavStoreInitial } from "./Nav.store";
import {
    TraineeVacationStore,
    TraineeVacationStoreInitial,
} from "../views/forms/TraineeVacation/TraineeVacation.store";
import {
    AdminTraineeStore,
    AdminTraineeStoreInitial,
} from "../views/admin_trainee/AdminTrainee.store";
import {
    AdminRecordBookListByTraineeStore,
    AdminRecordBookListByTraineeStoreInitial,
} from "../views/admin_recordbook_list_by_trainee/AdminRecordBookListByTrainee.store"
import {
    AdminTraineeTestApplyStatusStore,
    AdminTraineeTestApplyStatusStoreInitial,
} from "../views/admin_trainee_test_apply_status/AdminTraineeTestApplyStatus.store"
import {
    TrainerCreateStore,
    TrainerCreateStoreInitial,
} from "../views/trainer_create/TrainerCreate.store"

export const RootStore = types
    .model("RootStore", {
        toasterStore: types.optional(ToasterStore, () =>
        ToasterStore.create(ToasterStoreInitial)
        ),
        trainerCreateStore: types.optional(TrainerCreateStore, () =>
        TrainerCreateStore.create(TrainerProfileStoreInitial)
        ),
        adminTransferRequestStore: types.optional(AdminTransferRequestStore, () =>
            AdminTransferRequestStore.create(AdminTransferRequestStoreInitial)
        ),
        adminTraineeTestApplyStatusStore: types.optional(AdminTraineeTestApplyStatusStore, () =>
            AdminTraineeTestApplyStatusStore.create(AdminTraineeTestApplyStatusStoreInitial)
        ),
        adminTraineeStore: types.optional(AdminTraineeStore, () =>
            AdminTraineeStore.create(AdminTraineeStoreInitial)
        ),
        traineeVacationStore: types.optional(TraineeVacationStore, () =>
            TraineeVacationStore.create(TraineeVacationStoreInitial)
        ),
        traineeTestApplyTrainerReviewStore: types.optional(
            TraineeTestApplyTrainerReviewStore,
            () =>
                TraineeTestApplyTrainerReviewStore.create(
                    TraineeTestApplyTrainerReviewStoreInitial
                )
        ),
        traineeTestApplyStore: types.optional(TraineeTestApplyStore, () =>
            TraineeTestApplyStore.create(TraineeTestApplyStoreInitial)
        ),
        transferRequestStore: types.optional(TransferRequestStore, () =>
            TransferRequestStore.create(TransferRequestStoreInitial)
        ),
        importRecordBookStore: types.optional(ImportRecordBookStore, () =>
            ImportRecordBookStore.create(ImportRecordBookStoreInitial)
        ),
        recordBookStore: types.optional(RecordBookStore, () =>
            RecordBookStore.create(RecordBookStoreInitial)
        ),
        noticeListStore: types.optional(NoticeListStore, () =>
            NoticeListStore.create(NoticeListStoreInitial)
        ),
        tttApplyModalStore: types.optional(TtTApplyModalStore, () =>
            TtTApplyModalStore.create(TtTApplyModalStoreInitial)
        ),
        navStore: types.optional(NavStore, () => NavStore.create(NavStoreInitial)),
        uploadModalStore: types.optional(UploadModalStore, () =>
            UploadModalStore.create(UploadModalStoreInitial)
        ),
        viewUploadModalStore: types.optional(ViewUploadModalStore, () =>
            ViewUploadModalStore.create(ViewUploadModalStoreInitial)
        ),
        profileStore: types.optional(ProfileStore, () =>
            ProfileStore.create(ProfileStoreInitial)
        ),
        trainerProfileStore: types.optional(TrainerProfileStore, () =>
            TrainerProfileStore.create(TrainerProfileStoreInitial)
        ),
        traineeProfileStore: types.optional(TraineeProfileStore, () =>
            TraineeProfileStore.create(TraineeProfileStoreInitial)
        ),
        formTaskStore: types.optional(FormTaskStore, () =>
            FormTaskStore.create(FormTaskStoreInitial)
        ),
        messageBoxStore: types.optional(MessageBoxStore, () =>
            MessageBoxStore.create({ MessageBoxStoreInitial })
        ),
        accountStore: types.optional(AccountStore, () => AccountStore.create({})),
        authStore: types.optional(AuthStore, () =>
            AuthStore.create(AuthStoreInitial)
        ),
        trainerLessonPlanStore: types.optional(TrainerLessonPlanStore, () =>
            TrainerLessonPlanStore.create(TrainerLessonPlanStoreInitial)
        ),
        traineeCalendarStore: types.optional(TraineeCalendarStore, () =>
            TraineeCalendarStore.create(TraineeCalendarStoreInitial)
        ),
        // myRecordBookStatusStore: types.optional(MyRecordBookStatusStore, () => MyRecordBookStatusStore.create(MyRecordBookStatusStoreInitial)),
        traineeRecordBookStatusStore: types.optional(
            TraineeRecordBookStatusStore,
            () =>
                TraineeRecordBookStatusStore.create(TraineeRecordBookStatusStoreInitial)
        ),
        createRecordBookStore: types.optional(CreateRecordBookStore, () =>
            CreateRecordBookStore.create(CreateRecordBookStoreInitial)
        ),
        userInfoStore: types.optional(UserInfoStore, () =>
            UserInfoStore.create(UserInfoStoreInitial)
        ),
        lessonListStore: types.optional(LessonListStore, () =>
            LessonListStore.create(LessonListStoreInitial)
        ),
        confirmRecordBookStore: types.optional(ConfirmRecordBookStore, () =>
            ConfirmRecordBookStore.create(ConfirmRecordBookStoreInitial)
        ),
        noticeStore: types.optional(NoticeStore, () =>
            NoticeStore.create(NoticeStoreInitial)
        ),
        noticeCalendarStore: types.optional(NoticeCalendarStore, () =>
            NoticeCalendarStore.create(NoticeCalendarStoreInitial)
        ),
        laborContractFormStore: types.optional(LaborContractFormStore, () =>
            LaborContractFormStore.create(LaborContractFormStoreInitial)
        ),
        dashboardStore: types.optional(DashboardStore, () =>
            DashboardStore.create(DashboardStoreInitial)
        ),
        adminLessonListStore: types.optional(AdminLessonListStore, () =>
            AdminLessonListStore.create(AdminLessonListStoreInitial)
        ),
        adminTraineeListStore: types.optional(AdminTraineeListStore, () =>
            AdminTraineeListStore.create(AdminTraineeListStoreInitial)
        ),
        adminTrainerListStore: types.optional(AdminTrainerListStore, () =>
            AdminTrainerListStore.create(AdminTrainerListStoreInitial)
        ),
        adminRecordBookListStore: types.optional(AdminRecordBookListStore, () =>
            AdminRecordBookListStore.create(AdminRecordBookListStoreInitial)
        ),
        adminRecordBookListByTraineeStore: types.optional(AdminRecordBookListByTraineeStore, () =>
            AdminRecordBookListByTraineeStore.create(AdminRecordBookListByTraineeStoreInitial)
        ),
        traineeRecordBookListStore: types.optional(TraineeRecordBookListStore, () =>
            TraineeRecordBookListStore.create(TraineeRecordBookListStoreInitial)
        ),
        isLoading: false,
    })
    .actions((self) => ({
        setIsLoading(isLoading) {
            self.isLoading = isLoading;
        },
    }));

const MSTContext = createContext(null);

export const Provider = MSTContext.Provider;

export function useMst(mapStateToProps) {
    const store = useContext(MSTContext);
    if (typeof mapStateToProps !== "undefined") {
        return mapStateToProps(store);
    }
    return store;
}
