import { types, flow, getParent, applySnapshot } from "mobx-state-tree";
import { values } from "mobx";
import _ from "lodash";
import mockData from "./__mocks__/TrainerLessonPlan.store.data";
import {
  CategoryModel,
  SemesterModel,
  SubjectModel,
  CategoryModelInitial,
} from "../../store/Course.store";
import axios from "../../store/axios";
import moment from "moment";

export const TrainerLessonPlanStoreInitial = {
  categories: CategoryModelInitial,
  trainees: {},
  years: {},
  selectedChapter: "A",
};

export const TraineeLessonStatesEntry = types
  .model("TraineeLessonStatesEntry", {
    id: types.identifier,
    traineeId: types.integer,
    subjectId: types.integer,
    assignedDays: types.integer,
    confirmedRecordBooks: types.integer,
    confirmRequest: types.integer,
    traineeName: types.string,
    enterYear: types.integer,
  })
  .actions((self) => {
    return {
      setLesson: flow(function* ({ start, end }) {
        self.assignedDays = moment(end).diff(start, "days") + 1;
        yield axios.post("/lesson", {
          traineeId: self.traineeId,
          subjectId: self.subjectId,
          start,
          end,
        });
      }),
    };
  })
  .views((self) => ({
    get confirmCounts() {
      return `(${self.confirmedRecordBooks}/${self.assignedDays})`;
    },
    get stateColor() {
      return self.assignedDays === 0
        ? "secondary"
        : self.confirmedRecordBooks / self.assignedDays >= 1
        ? "success"
        : "warning";
    },
  }));

export const SubjectEntry = types
  .compose(
    SubjectModel,
    types.model({ traineeLessonStates: types.array(TraineeLessonStatesEntry) })
  )
  .views((self) => ({
    get TraineeLessonStates() {
      const storeThis = getParent(self, 6);
      const selectedYears = values(storeThis.years)
        .filter((_) => _.selected)
        .map((_) => _.year);
      // if(selectedYears){
      const filteredStates = values(self.traineeLessonStates).filter((state) =>
        selectedYears.includes(state.enterYear)
      );
      return filteredStates;
      // }
      // return [];
      // return values(self.traineeLessonStates);
    },
  }))
  .named("SubjectEntry");

const SemesterEntry = types
  .compose(SemesterModel, types.model({ subjects: types.array(SubjectEntry) }))
  .views((self) => ({
    get Subjects() {
      return values(self.subjects);
    },
    //각 트레이니의 가나 통계
    get TraineeSemesterState() {
      const root = getParent(self, 4);
      const trainees = root.Trainees;
      return trainees.map((trainee) => {
        let result = [];
        for (const s of self.subjects) {
          const traineeCol = s.TraineeLessonStates.find(
            (_) => _.traineeId === trainee.id
          );
          trainee && result.push(traineeCol);
        }
        const confirmedRecordBooksSum = result.reduce(
          (a, b) => b && a + b.confirmedRecordBooks,
          0
        );
        const label =
          confirmedRecordBooksSum === 0 || self.requiredDays === 0
            ? "secondary"
            : confirmedRecordBooksSum / self.requiredDays >= 1
            ? "success"
            : "warning";
        return {
          traineeName: trainee.displayName,
          traineeId: trainee.id,
          confirmedDays: confirmedRecordBooksSum,
          targetDays: self.requiredDays,
          stateColor: label,
        };
      });
    },
  }))
  .named("SemesterEntry");

const TraineeEntry = types.model("TraineeEntry", {
  id: types.identifierNumber,
  displayName: types.string,
  enterYear: types.integer,
});

export const CategoryEntry = types
  .compose(
    CategoryModel,
    types.model({
      semesters: types.array(SemesterEntry),
    })
  )
  .views((self) => ({
    get Semesters() {
      return values(self.semesters);
    },
  }))
  .named("CategoryEntry");

export const EnterYearModel = types.model("EnterYear", {
  year: types.identifierNumber,
  selected: types.boolean,
});

export const TrainerLessonPlanStore = types
  .model("TrainerLessonPlanStore", {
    categories: types.map(CategoryEntry),
    trainees: types.map(TraineeEntry),
    selectedSubject: types.optional(types.safeReference(SubjectEntry)),
    years: types.map(EnterYearModel),
    selectedChapter: types.optional(
      types.enumeration("SelectedChapter", ["A", "B", "국내규정"]),
      "A"
    ),
  })
  .views((self) => ({
    get Categories() {
      return values(self.categories);
    },
    get Trainees() {
      const selectedYears = values(self.years)
        .filter((x) => x.selected)
        .map((x) => x.year);
      return values(self.trainees).filter((trainee) =>
        selectedYears.includes(trainee.enterYear)
      );
    },
    get EnterYears() {
      //  return [...new Set(self.trainees.map(t => t.enterYear))];
      return values(self.years);
    },
    get SelectedEnterYears() {
      return values(self.years)
        .filter((_) => _.selected)
        .map((_) => _.year);
    },
  }))
  .actions((self) => {
    return {
      selectChapter: (chapter) => {
        self.selectedChapter = chapter;
      },

      toggleEnterYear: flow(function* (year) {
        self.years.get(year).selected = !self.years.get(year).selected;
        yield self.fetch(self.selectedChapter);
      }),

      unselectEnterYear: (year) => {
        self.years.get(year).selected = false;
      },
      fetchCategory: flow(function* () {
        const response = yield axios.get("/lesson/course");
        // self.categories = mockData.categories;
        response.data.categories.forEach((category) => {
          self.categories.put(category);
        });

        const dYears = response.data.traineeYears;

        dYears.forEach((year) => {
          self.years.put({ year, selected: false });
        });

        return Math.max(...response.data.traineeYears);
      }),
      fetchLessonStates: flow(function* (maxYear) {
        const stateRes = yield axios.get(`/lesson/trainee-states`);
        stateRes.data.trainees.forEach((_) => self.trainees.put(_));

        //initial selected year
        self.toggleEnterYear(maxYear);

        const traineeLessonStatesAll = stateRes.data.traineeLessonStates;

        values(self.categories).forEach((cat) => {
          cat.semesters.forEach((sem) => {
            sem.subjects.forEach((sub) => {
              const subState = traineeLessonStatesAll.filter(
                (state) => state.subjectId === sub.id
              );
              subState.forEach((_) => sub.traineeLessonStates.put(_));
            });
          });
        });
      }),

      fetch_bak: flow(function* () {
        //TODO: 카테고리 목목 가져오기 부터 시작
        // const maxYear = yield self.fetchCategory();
        // yield self.fetchLessonStates(maxYear); //

        const stateRes = yield axios.get(`/lesson/trainee-states`);

        if (self.years.size === 0) {
          const distYears = [
            ...new Set(stateRes.data.trainees.map((_) => _.enterYear)),
          ];
          const maxYear = distYears.reduce((a, b) => (a > b ? a : b));
          distYears
            .sort((a, b) => b - a)
            .forEach((_) =>
              self.years.put({ year: _, selected: maxYear === _ })
            );
        }
        stateRes.data.categories.forEach((_) => self.categories.put(_));
        stateRes.data.trainees.forEach((_) => self.trainees.put(_));
        // self.trainees = stateRes.data.trainees;
      }),

      fetchMyTrainees: flow(function* () {
        if (self.Trainees.length === 0) {
          const traineeResponse = yield axios.get("/lesson/my-trainees");
          traineeResponse.data.trainees.forEach((_) => self.trainees.put(_));
        }
      }),

      fetch: flow(function* (chapter) {
        //TODO: 카테고리 목목 가져오기 부터 시작
        // const maxYear = yield self.fetchCategory();
        // yield self.fetchLessonStates(maxYear); //
        if (self.Trainees.length === 0) {
          const traineeResponse = yield axios.get("/lesson/my-trainees");
          console.log("TrainerLessonPlan fetch data", traineeResponse.data)

          if (traineeResponse.data.trainees.length === 0) { //같은 소속 트레이니 없는 경우
            return;
          } else {
            traineeResponse.data.trainees.forEach((_) => self.trainees.put(_));

            // self.trainees = traineeResponse.data.trainees;

            if (self.years.size === 0) {
              const distYears = [
                ...new Set(
                  traineeResponse.data.trainees.map((_) => _.enterYear)
                ),
              ];
              const maxYear = distYears.reduce((a, b) => (a > b ? a : b));
              distYears
                .sort((a, b) => b - a)
                .forEach((_) =>
                  self.years.put({ year: _, selected: maxYear === _ })
                );
            }
          }
        }
        const stateRes = yield axios.post(`/lesson/course-trainees`, {
          selectedEnterYears: self.EnterYears.filter((_) => _.selected).map(
            (_) => _.year
          ),
          chapter,
        });

        stateRes.data.categories.forEach((_) => self.categories.put(_));
        // stateRes.data.trainees.forEach((_) => self.trainees.put(_));
        // self.trainees = stateRes.data.trainees;
      }),

      getCategoryBy: (chapter) => {
        return values(self.categories).filter((c) => c.chapter === chapter);
      },
    };
  });

export default TrainerLessonPlanStore;
