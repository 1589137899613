/* eslint-disable import/no-anonymous-default-export */

import dayjs from "dayjs";
const currDate = new Date();
const currYear = currDate.getFullYear();
const currMonth = currDate.getMonth();

const getDate = (date) => {
  return dayjs(new Date(currYear, currMonth, date, 23)).format("YYYY-MM-DD");
};
export default {
  traineeYears: [2017, 2018, 2020],

  traineeLessonStates: [
    {
      id: "1_1",
      subjectId: 1,
      traineeId: 1,
      traineeName: "류관순",
      assignedDays: 1,
      confirmedRecordBooks: 1,
      confirmRequest: 0,
    },
    {
      id: "1_2",
      subjectId: 1,
      traineeId: 2,
      traineeName: "김구",
      assignedDays: 2,
      confirmedRecordBooks: 1,
      confirmRequest: 1,
    },
    {
      id: "1_3",
      subjectId: 1,
      traineeId: 3,
      traineeName: "윤봉길",
      assignedDays: 0,
      confirmedRecordBooks: 0,
      confirmRequest: 0,
    },

    {
      id: "2_1",
      subjectId: 2,
      traineeId: 1,
      traineeName: "류관순",
      assignedDays: 1,
      confirmedRecordBooks: 1,
      confirmRequest: 0,
    },
    {
      id: "2_2",
      subjectId: 2,
      traineeId: 2,
      traineeName: "김구",
      assignedDays: 2,
      confirmedRecordBooks: 1,
      confirmRequest: 1,
    },
    {
      id: "2_3",
      subjectId: 2,
      traineeId: 3,
      traineeName: "윤봉길",
      assignedDays: 0,
      confirmedRecordBooks: 0,
      confirmRequest: 0,
    },

    {
      id: "3_1",
      subjectId: 3,
      traineeId: 1,
      traineeName: "류관순",
      assignedDays: 1,
      confirmedRecordBooks: 1,
      confirmRequest: 0,
    },
    {
      id: "3_2",
      subjectId: 3,
      traineeId: 2,
      traineeName: "김구",
      assignedDays: 2,
      confirmedRecordBooks: 1,
      confirmRequest: 0,
    },
    {
      id: "3_3",
      subjectId: 3,
      traineeId: 3,
      traineeName: "윤봉길",
      assignedDays: 0,
      confirmedRecordBooks: 0,
      confirmRequest: 0,
    },
    {
      id: "4_1",
      subjectId: 4,
      traineeId: 1,
      traineeName: "류관순",
      assignedDays: 1,
      confirmedRecordBooks: 1,
      confirmRequest: 0,
    },
    {
      id: "4_2",
      subjectId: 4,
      traineeId: 2,
      traineeName: "김구",
      assignedDays: 2,
      confirmedRecordBooks: 1,
      confirmRequest: 0,
    },
    {
      id: "4_3",
      subjectId: 4,
      traineeId: 3,
      traineeName: "윤봉길",
      assignedDays: 0,
      confirmedRecordBooks: 0,
      confirmRequest: 0,
    },
  ],

  trainees: [
    { id: 1, displayName: "류관순", enterYear: 2019 },
    { id: 2, displayName: "김구", enterYear: 2018 },
    { id: 3, displayName: "윤봉길", enterYear: 2019 },
  ],

  categories: [
    {
      id: 1,
      title: "차량과 시스템의 조작",
      source: "(제4조 3항 1번)",
      chapter: "A",
      semesters: [
        {
          id: 1,
          semesterType: "가",
          requiredDays: 5.5,
          subjects: [
            {
              id: 1,
              title: "안전과 조작에 대한 규정과 지시사항 주의 및 적용",
              charIndex: "a",
              traineeLessonStates: [
                {
                  id: "1_1",
                  subjectId: 1,
                  traineeId: 1,
                  traineeName: "류관순",
                  assignedDays: 1,
                  confirmedRecordBooks: 1,
                  confirmRequest: 0,
                  enterYear: 2019,
                },
                {
                  id: "1_2",
                  subjectId: 1,
                  traineeId: 2,
                  traineeName: "김구",
                  assignedDays: 2,
                  confirmedRecordBooks: 1,
                  confirmRequest: 1,
                  enterYear: 2018,
                },
                {
                  id: "1_3",
                  subjectId: 1,
                  traineeId: 3,
                  traineeName: "윤봉길",
                  assignedDays: 0,
                  confirmedRecordBooks: 0,
                  confirmRequest: 0,
                  enterYear: 2019,
                },
              ],
            },
            {
              id: 2,
              title: "안전과 조작에 대한 규정과 지시사항 주의 및 적용",
              charIndex: "b",
              traineeLessonStates: [
                {
                  id: "2_1",
                  subjectId: 2,
                  traineeId: 1,
                  traineeName: "류관순",
                  assignedDays: 1,
                  confirmedRecordBooks: 1,
                  confirmRequest: 0,
                  enterYear: 2019,
                },
                {
                  id: "2_2",
                  subjectId: 2,
                  traineeId: 2,
                  traineeName: "김구",
                  assignedDays: 2,
                  confirmedRecordBooks: 1,
                  confirmRequest: 1,
                  enterYear: 2018,
                },
                {
                  id: "2_3",
                  subjectId: 2,
                  traineeId: 3,
                  traineeName: "윤봉길",
                  assignedDays: 0,
                  confirmedRecordBooks: 0,
                  confirmRequest: 0,
                  enterYear: 2019,
                },

              ]
            },
          ],
        },
        {
          id: 2,
          semesterType: "나",
          requiredDays: 3,
          subjects: [
            {
              id: 3,
              title: "안전과 조작에 대한 규정과 지시사항 주의 및 적용",
              charIndex: "a",
              traineeLessonStates: [
                {
                  id: "3_1",
                  subjectId: 3,
                  traineeId: 1,
                  traineeName: "류관순",
                  assignedDays: 1,
                  confirmedRecordBooks: 1,
                  confirmRequest: 0,
                  enterYear: 2019,
                },
                {
                  id: "3_2",
                  subjectId: 3,
                  traineeId: 2,
                  traineeName: "김구",
                  assignedDays: 2,
                  confirmedRecordBooks: 1,
                  confirmRequest: 0,
                  enterYear: 2018,
                },
                {
                  id: "3_3",
                  subjectId: 3,
                  traineeId: 3,
                  traineeName: "윤봉길",
                  assignedDays: 0,
                  confirmedRecordBooks: 0,
                  confirmRequest: 0,
                  enterYear: 2019,
                },

              ]
            },
            {
              id: 4,
              title: "안전과 조작에 대한 규정과 지시사항 주의 및 적용",
              charIndex: "b",
              traineeLessonStates: [
                {
                  id: "4_1",
                  subjectId: 4,
                  traineeId: 1,
                  traineeName: "류관순",
                  assignedDays: 1,
                  confirmedRecordBooks: 1,
                  confirmRequest: 0,
                  enterYear: 2019,
                },
                {
                  id: "4_2",
                  subjectId: 4,
                  traineeId: 2,
                  traineeName: "김구",
                  assignedDays: 2,
                  confirmedRecordBooks: 1,
                  confirmRequest: 0,
                  enterYear: 2018,
                },
                {
                  id: "4_3",
                  subjectId: 4,
                  traineeId: 3,
                  traineeName: "윤봉길",
                  assignedDays: 0,
                  confirmedRecordBooks: 0,
                  confirmRequest: 0,
                  enterYear: 2019,
                },

              ]
            },
          ],
        },
      ],
    },
  ],
};
