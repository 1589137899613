import { getParent, types, flow, isAlive, cast } from "mobx-state-tree";
import { values } from "mobx";
import axios from "../../store/axios";
import { FieldModel } from "../../store/ListField.model";

import DateTime from "../../store/DateTime";

export const AdminTransferRequestStoreInitial = {
    list: [],
    currentPage: 1,
};


const AdminTransferRequestEntry = types.model("AdminTraineeTestApplyEntry", {
    userId: types.integer,
    displayName: types.string,
    userType: types.string,
    from: types.string,
    to: types.string,
    created: types.maybeNull(DateTime),
});

export const AdminTransferRequestStore = types
    .model("AdminTransferRequestStore", {
        list: types.array(AdminTransferRequestEntry),
        fields: types.array(FieldModel),
        currentPage: types.integer,
    })
    .views((self) => ({
        get List() {
            return values(self.list);
        },
        get Pages() {
            return self.pages;
        },
    }))
    .actions((self) => {
        return {
            decline: flow(function* (id) {
                // const rootStore = getParent(self, 1);
                console.log("decline", id);
                const url = `/transfer-request/${id}`;
                const response = yield axios.delete(
                    url
                );
                console.log(url, response.data);
            }),
            confirm: flow(function* (id) {
                // const rootStore = getParent(self, 1);
                console.log("confirm", id);
                const url = `/transfer-request/allow-transfer/${id}`;
                const response = yield axios.put(
                    url
                );
            }),
            fetch: flow(function* (page) {
                // const rootStore = getParent(self, 1);
                console.log("fetch", page);
                const url = `/transfer-request/list?_page=${page}&_limit=24`;
                const response = yield axios.get(
                    url
                );
                console.log(url, response.data);
                self.pages = response.data.pages;
                return response.data.list;
            }),
        };
    });

export default AdminTransferRequestStore;
