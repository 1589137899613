/* eslint-disable import/no-anonymous-default-export */
const currDate = new Date();
const currYear = currDate.getFullYear();
const currMonth = currDate.getMonth();

export default {
  recordBooks: [
    {
      id: 1,
      subjectId: 1,
      date: new Date(currYear, currMonth - 2, 1),
      semesterType: "가",
    },
    {
      id: 2,
      subjectId: 1,
      date: new Date(currYear, currMonth - 1, 2),
      semesterType: "가",
    },
    {
      id: 3,
      subjectId: 2,
      date: new Date(currYear, currMonth, 12),
      semesterType: "가",
    },
    {
      id: 4,
      subjectId: 2,
      date: new Date(currYear, currMonth, 10),
      semesterType: "가",
    },
    {
      id: 5,
      subjectId: 2,
      date: new Date(currYear, currMonth, 20),
      semesterType: "가",
    },
    {
      id: 6,
      subjectId: 4,
      date: new Date(currYear, currMonth, 2),
      semesterType: "나",
    },
    {
      id: 7,
      subjectId: 2,
      date: new Date(currYear, currMonth, 3),
      semesterType: "가",
    },
    {
      id: 8,
      subjectId: 3,
      date: new Date(currYear, currMonth, 8),
      semesterType: "나",
    },
    {
      id: 9,
      subjectId: 5,
      date: new Date(currYear, currMonth, 11),
      semesterType: "전체",
    }
  ],
  categories: [
    {
      id: 1,
      title: "차량과 시스템의 조작",
      source: "(제4조 3항 1번)",
      chapter: "A",
      semesters: [
        {
          id: 1,
          semesterType: "가",
          requiredDays: 5.5,
          subjects: [
            {
              id: 1,
              title: "안전과 조작에 대한 규정과 지시사항 주의 및 적용",
              charIndex: "a",
              assignedDays: 2, //트레이너가 할당한 날짜
            },
            {
              id: 2,
              title: "안전과 조작에 대한 규정과 지시사항 주의 및 적용",
              charIndex: "b",
              assignedDays: 4,
            },
          ],
        },
        {
          id: 2,
          semesterType: "나",
          requiredDays: 3.5,
          subjects: [
            {
              id: 3,
              title: "안전과 조작에 대한 규정과 지시사항 주의 및 적용",
              charIndex: "a",
              assignedDays: 2, //트레이너가 할당한 날짜
            },
            {
              id: 4,
              title: "안전과 조작에 대한 규정과 지시사항 주의 및 적용",
              charIndex: "b",
              assignedDays: 2,
            },
          ],
        },
        {
          id: 2,
          semesterType: "전체",
          requiredDays: 5.5,
          subjects: [
            {
              id: 5,
              title: "안전과 조작에 대한 규정과 지시사항 주의 및 적용",
              charIndex: "a",
              assignedDays: 1, //트레이너가 할당한 날짜
            },
            {
              id: 6,
              title: "안전과 조작에 대한 규정과 지시사항 주의 및 적용",
              charIndex: "b",
              assignedDays: 1,
            },
          ],
        }
      ],
    },
  ],
};
