import { flow, types, destroy } from "mobx-state-tree";
import axios from "axios";
import { values } from "mobx";

// const SERVER_URL = process.env.REACT_APP_API_SERVER;
const SERVER_URL = window._env_.API_URL;

export const FileModel = types
  .model("FileModel", {
    id: types.identifierNumber,
    fileName: types.string,
  })
  .views((self) => ({
    get IsImage() {
      var ext = self.fileName.split(".").pop();
      return ext === "jpg" || ext === "png" || ext === "jpeg" || ext === "gif";
    },
  }))
  .actions((self) => {
    return {
      download: flow(function* () {
        const authToken = JSON.parse(sessionStorage.getItem("tokens")).authToken;

        const response = yield axios.get(`${SERVER_URL}/file/download/${self.id}`, {
          responseType: "blob", // important
          headers: { Authorization: `Bearer ${authToken}` },
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", self.fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
      }),
    };
  });

export const FileStore = types
  .model("FileStore", {
    list: types.array(FileModel),
    isLoading: false,
  })
  .actions((self) => ({
    deleteFile: flow(function* (file) {
      console.log("deleteFile", file);
      const authToken = JSON.parse(sessionStorage.getItem("tokens")).authToken;
      const url = `${SERVER_URL}/file/${file.id}`;
      yield axios.delete(url,{
          headers: { Authorization: `Bearer ${authToken}` },
      });
      destroy(file);
    }),

    load: (files) => {
      self.list.clear();
        console.log("files self.list", self.list)
      console.log("load", files);
      self.list = files;
    },
      
    clear: (files) => {
        self.list.clear();
    },

    upload: flow(function* (e, fileType, signDate) {
      if (e.target.files.length === 0) {
        return;
      }
      let formData = new FormData();
      formData.append("formFile", e.target.files[0]);
      formData.append("fileName", e.target.files[0].name);

      const authToken = JSON.parse(sessionStorage.getItem("tokens")).authToken;
      let res;
      if (fileType) {
        formData.append("signDate", signDate);
        res = yield axios.post(
          `${SERVER_URL}/file/${fileType}/upload`,
          formData,
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        );
      } else {
        res = yield axios.post(
          `${SERVER_URL}/file/attachment/upload`,
          formData,
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        );
      }

      self.list.push({ ...res.data });
      console.log("fileStore list update", self.list);
    }),
  }))
  .views((self) => ({
    get Ids() {
      return values(self.list).map((_) => _.id);
    },
    get List() {
      return values(self.list);
    },
  }));

export default FileStore;
