//트레이너가 할당한 레슨의 상태
import {
  destroy,
  types,
  flow,
  getParent,
  applySnapshot,
} from "mobx-state-tree";
import _ from "lodash";
import axios from "../../store/axios";
import dayjs from "dayjs";
import { values } from "mobx";
import { TraineeLessonStatesEntry } from "../trainer_lesson_plan/TrainerLessonPlan.store";
import DateTime from "../../store/DateTime";
import {FileModel} from "../../store/FileStore";

export const SubjectEntityInitial = {id: 0, title: "", subjectIndex: ""}
export const LessonListStoreInitial = {
  lessons: {},
  subject: SubjectEntityInitial,
  trainee: {id: 0, displayName: ""},
  categoryTitle : ""
};

const RecordBookStateEntry = types.model("RecordBookStateEntry", {
  id: types.identifier,
  date: types.maybe(DateTime),
  state: types.enumeration("state", ["확인요청", "확인완료", "미확인"]),
  recordBookId: types.integer,
});

const RecordBookEntry = types.model("RecordBookEntry", {
  id: types.identifierNumber,
  date: types.maybe(DateTime),
  state: types.enumeration("state", ["확인요청", "확인완료", "미확인"]),
  content: types.maybeNull(types.string),
  files: types.array(FileModel),
})
;

const LessonEntry = types
  .model("LessonEntry", {
    id: types.identifierNumber,
    create: types.maybe(DateTime),
    start: types.maybe(DateTime),
    end: types.maybe(DateTime),
    recordBooks: types.array(RecordBookEntry),
  description: types.maybeNull(types.string),
  action: types.maybeNull(types.string),
  })
  .actions((self) => {
    return {
      delete: flow(function* () {
        yield axios.delete(`/lesson/${self.id}`);
        const parent = getParent(self, 2);
        parent.removeChild(self);
      }),
    };
  })
  .views((self) => ({
    get IsRemovable() {
      return self.RecordBookStates.every((_) => _.state === "미작성");
    },
    get RecordBookStates() {
      const result = [];
      const dayDiffs = dayjs(self.end).diff(dayjs(self.start), "day") + 1;
      console.log("dayDiffs:", dayDiffs);
      for (let index = 0; index < dayDiffs; index++) {
        const foundRbs = values(self.recordBooks).find((recordBook) =>
          dayjs(recordBook.date).isSame(self.start.add(index, "day"))
        );
        let newRbs;
        if (foundRbs) {
          newRbs = {
            id: self.id,
            date: dayjs(self.start).add(index, "day"),
            recordBookId: foundRbs.id,
            state: foundRbs.state,
            content : foundRbs.content,
            files: foundRbs.files,
          };
        } else {
          newRbs = {
            id: index + "_" + self.id,
            date: dayjs(self.start).add(index, "day"),
            recordBookId: 0,
            state: "미작성",
          };
        }
        result.push(newRbs);
      }
      return result;
    },
  }));

  export const SubjectEntity = types.model("SubjectEntity", {
    id: types.identifierNumber,
    title: types.string,
    subjectIndex: types.string,
  });
  export const TraineeEntity = types.model("TraineeEntity", {
    id: types.identifierNumber,
    displayName: types.string,
  })
export const LessonListStore = types
  .model("LessonListStore", {
    lessons: types.map(LessonEntry),
    selectedLessonState: types.safeReference(TraineeLessonStatesEntry),
    subject: SubjectEntity,
    trainee: TraineeEntity,
    categoryTitle : types.string,
    error: ""
  })
  .views((self) => ({
    get Lessons() {
      return values(self.lessons);
    },
  }))
  .actions((self) => {
    const makeLessonTree = (lessons) => {
      lessons.forEach((lesson) => {
        self.lessons.put(lesson);
      }
    );
  };
    return {
      saveConfirmation: flow(function* ({recordBookId}){
        yield axios.post(`/record-book/confirm`, {recordBookId});
      }),
      setConfirmed: ({lessonId, recordBookId}) =>{
        self.lessons.get(lessonId).recordBooks.find(x => x.id === recordBookId).state = "확인완료";
          //traineeRecordbookStatusStore
        const traineeRecordbookStatusStore = getParent(self,1).traineeRecordbookStatusStore;
        if(traineeRecordbookStatusStore){
            traineeRecordbookStatusStore.setRecordbookState(recordBookId, "확인완료");
        }
      },

      clearError:() => {
        self.error = "";
      },
      setError:(msg) => {
        self.error = msg ;
      },
      removeChild: (item) => destroy(item),
      addLesson: flow(function* ({ date, description, action, traineeId, subjectId }) {
        yield axios.post(`/lesson`, {
          start: dayjs(date).format("YYYY-MM-DD"), // 시간 분리
          end: dayjs(date).format("YYYY-MM-DD"),
          description,
          action,
          traineeId,
          subjectId,
        }).catch(e => {
          if(e.response.data === "LESSON_OVERLAPPING"){
            self.setError ( "해당 트레이니의 레코드 북 작성 일자가 겹치는 날이 있습니다.");
          }
        })

        yield self.fetch({traineeId, subjectId});
      }),

      setSelectedLessonState: (lessonState) => {
        self.selectedLessonState = lessonState;
      },
      reset(){
        self.lessons = {};
        self.subject = {id: 0, title:"", subjectIndex:""};
        self.trainee = {id: 0, displayName:""};
        self.categoryTitle = "";
      },
      fetch: flow(function* ({ traineeId, subjectId }) {
        self.reset();
        const result = yield axios.get(
          `/lesson/record-books/${subjectId}/${traineeId}`
        );
        console.log("[LessonListStore] result.data.lessons", result.data.lessons);
        makeLessonTree(result.data.lessons);
        self.subject = result.data.subject;
        self.trainee = result.data.trainee;
        self.categoryTitle = result.data.subject.categoryTitle;
        // self.clearError();
      }),
    };
  });

export default LessonListStore;
