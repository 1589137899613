import React, { useEffect, useState }from 'react';
import { HashRouter,BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { RootStore, Provider as MSTStore } from "./store/RootStore";
import { AuthContext } from "./context/auth";
import PrivateRoute from './PrivateRoute';

import './scss/style.scss';

//.env environment variables loading
const API_SERVER = process.env.REACT_APP_API_SERVER;

const AppLoading = () => (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Email App
const TheEmailApp = React.lazy(() => import('./views/apps/email/TheEmailApp'));

// Pages
const Opening = React.lazy(() => import('./views/pages/opening/Opening'));
const Login = React.lazy(() => import('./views/pages/login/Login'));
const SendConfirmEmail = React.lazy(() => import('./views/pages/send_confirm_email/SendConfirmEmail.view'));
const ConfirmEmail = React.lazy(() => import('./views/pages/confirm_email/ConfirmEmail.view'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const ForgotPassword = React.lazy(() => import('./views/pages/forgot_password/ForgotPassword.view'));
const ResetPassword = React.lazy(() => import('./views/pages/reset_password/ResetPassword.view'));

function App () {
  const rootStore = RootStore.create();
  const [authTokens, setAuthTokens] = useState( sessionStorage.getItem('tokens') || '');
  const setTokens = (data) => {
    sessionStorage.setItem("tokens", JSON.stringify(data.tokens));
    sessionStorage.setItem("userInfo", JSON.stringify(data.userInfo));
    setAuthTokens(data);
  }

  const logOut = () => {
    sessionStorage.clear();
    setAuthTokens("");
  }
  return (
    <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens, logOut }}>
    <MSTStore value={rootStore}>
      <Router>
        <React.Suspense fallback={<AppLoading />}>
          <Switch>
              <Route exact path="/opening" name="Opening" render={props => <Opening {...props}/>} />
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/send-confirm-email" name="계정 활성화 메일" render={props => <SendConfirmEmail {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route exact path="/forgot-password" name="암호 변경요청하기" render={props => <ForgotPassword {...props}/>} />
              <Route path="/reset-password/:userId/:token" name="암호 변경완료" render={props => <ResetPassword {...props}/>} />
              <Route path="/confirm-email/:userId/:token" name="계정 활성화" render={props => <ConfirmEmail {...props}/>} />
              <PrivateRoute path="/" name="Home" render={props => <TheLayout {...props}/>} />
              {/* <Route path="/" name="Home" render={props => <TheLayout {...props}/>} /> */}
            </Switch>
        </React.Suspense>
      </Router>
    </MSTStore>
   </AuthContext.Provider>
  );
}

export default App;
