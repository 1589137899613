import { getParent, types, flow, clone, applySnapshot } from "mobx-state-tree";
import { values } from "mobx";
import axios from "../../store/axios";
import dayjs from "dayjs";
import DateTime from "src/store/DateTime";
import {TransferRequestStatusModel, TransferRequestStatusModelInitial} from "../transfer_request/TransferRequestStatusModel";

const DateTimeInit = "0001-01-00";

export const ValueLabelEntry = types.model("ValueLabelEntry", {
  value: types.integer,
  label: types.string,
});

export const TraineeProfileStoreInitial = {
  entity: {
    highSchool: "",
    phoneNumber: "",
    birthday: DateTimeInit,
    displayName: "",
    organization: "",
    university: "",
    enterYear: 0,
    email:"",
    professionality: "",
  },
  brands: [],
  success: false,
  transferRequestStatus: TransferRequestStatusModelInitial,
  // selectedBrand: "",
  // selectedDealer: "",
  // selectedBranch: "",
};

export const TraineeProfileEntity = types
  .model("TraineeProfileEntity", {
    birthday: types.maybeNull(DateTime),
    displayName: types.string,
    highSchool:  types.maybeNull(types.string),
    phoneNumber:  types.maybeNull(types.string),
    organization: types.string,
    university: types.maybeNull(types.string),
    enterYear: types.maybeNull(types.integer),
    email: types.string,
    professionality: types.string
  })
  .actions((self) => {
    return {
      edit(name, value) {
        console.log("on value changed", name, value);
        self[name] = value;
      },
      // update: flow(function* () {
      //   const updateData = {
      //     highSchool: self.highSchool,
      //     university: self.university,
      //     enterYear: self.enterYear,
      //     birthday: self.birthday,
      //     phoneNumber: self.phoneNumber,
      //     email: self.email,
      //     displayName: self.displayName,
      //   }
      //   console.log("dataToUpdate:", updateData);
      //   yield axios.put(`profile/trainee`, updateData);
      //   getParent(self,1).setSuccess(true);
      //   console.log("TraineeProfile update", getParent(self, 2))
      //   getParent(self,2).profileStore.setDisplayName(self.displayName);
// }),
    };
  });


export const TraineeProfileStore = types
  .model("TraineeProfileStore", {
    entity: TraineeProfileEntity,
    success: types.boolean,
    transferRequestStatus: TransferRequestStatusModel,
    // brands: types.array(ValueLabelEntry),
    // dealers: types.array(ValueLabelEntry),
    // branches: types.array(ValueLabelEntry),
    // selectedBrand: types.maybeNull(types.reference(ValueLabelEntry)),
    // selectedDealer: types.reference(ValueLabelEntry),
    // selectedBranch: types.reference(ValueLabelEntry),
  })
  .views((self) => ({
    // get Brands() {
    //   return values(self.brands);
    // }
    // ,
    // get Dealers() {
    //   return values(self.dealers);
    // }
    // ,
    // get Branches() {
    //   return values(self.branches);
    // }
  }))
  .actions((self) => {
    return {
      setSuccess(val) {
        self.success = val;
      },
      selectBrand(valueLabel) {
        self.selectedBrand = valueLabel;
      },
      selectDealer(valueLabel) {
        self.selectedDealer = valueLabel;
      },
      selectBranch(valueLabel) {
        self.selectedBranch = valueLabel;
      },
      reset() {
        applySnapshot(self, TraineeProfileStoreInitial);
      },
      edit(name, value) {
        self[name] = value;
      },
      fetchEdit: flow(function* (id) {
        try {
          let endpoint = 'profile/trainee';
          if (id) {
            endpoint += `/${id}`;
          }
          const result = yield axios.get(endpoint);
          const modifiedData = { ...result.data };

          // birthDay 값 포맷 변경
          if (modifiedData.birthDay) {
            modifiedData.birthDay = dayjs(modifiedData.birthDay).local().format("YYYY-MM-DD");
          }

          // 수정된 데이터를 self.entity에 적용
          applySnapshot(self.entity, modifiedData);
        } catch (error) {
          console.error('An error occurred:', error);
        }
      }),
      fetchBranch: flow(function* ({ dealerId }) {
        const result = yield axios.get(`profile/branches/${dealerId}`);
        console.log("fetch branches", result.data);
        self.branches = result.data.map((_) => ({
          label: _.name,
          value: _.id,
        }));
      }),
      fetchDealer: flow(function* ({ brandId }) {
        console.log("fetch dealers brandId", brandId);
        const result = yield axios.get(`profile/dealers/${brandId}`);
        console.log("fetch dealers", result.data);
        self.dealers = result.data?.map((_) => ({
          label: _.name,
          value: _.id,
        }));
      }),
      fetchBrands: flow(function* () {
        const result = yield axios.get("profile/brands");
        console.log("fetch brands", result.data);
        self.brands = result.data?.map((_) => ({ label: _.name, value: _.id }));
        // applySnapshot(self.brands, result.data);
      }),
      handleBlur(fieldName) {
        self.touched[fieldName] = true;
      },
      update: flow(function* () {
        console.log("update ", self.toJSON());
        yield axios.put("profile/trainee", self);
      }),
      fetchTransferRequestStatus: flow(function*(id){
        console.log("transferRequestStatus ");
        const result = yield axios.get(`profile/transfer-request-status/${id}`);
        self.transferRequestStatus = result.data;
      })
    };
  });

export default TraineeProfileStore;
