import { getParent, types, flow, applySnapshot } from "mobx-state-tree";
import { values } from "mobx";
import axios from "../../store/axios";
import dayjs from "dayjs";
import DateTime from "src/store/DateTime";
import FileStore from "../../store/FileStore";

export const IdDisplayNameEntry = types.model("IdNameEntry", {
  id: types.identifierNumber,
  displayName: types.string,
});

export const NoticeStoreInitial = {
  entity: {
    id: 0,
    files: { list: [] },
    title: "",
    content: "",
    start: dayjs(),
    end: dayjs().add(7, "day"),
    expired: dayjs().add(30, "day"),
    created: dayjs(),
    targetGroup: {},
    brandId: null,
    author: null,
    pinned: false,
  },
  availableTargetGroups: [],
  notices: [],
  brands: [],
};

export const TargetGroupEntry = types.model("TargetGroupEntry", {
  id: types.identifierNumber,
  name: types.string,
});

export const BrandEntry = types.model("BrandEntry", {
  id: types.identifierNumber,
  name: types.string,
});

export const NoticeEntity = types
  .model("NoticeEntity", {
    id: types.integer,
    title: types.string,
    files: FileStore,
    content: types.string,
    start: types.maybeNull(DateTime),
    end: types.maybeNull(DateTime),
    expired: types.maybeNull(DateTime),
    created: types.maybeNull(DateTime),
    targetGroups: types.array(types.reference(TargetGroupEntry)),
    brandId: types.maybeNull(types.integer),
    author: types.maybeNull(IdDisplayNameEntry),
    pinned: types.boolean,
  })
  .views((self) => ({
    get Files() {
      return self.files.list.map((file) => ({ id: file.id, name: file.name }));
    },
    get Brands() {
      return values(self.brands);
    }
  }))
  .actions((self) => {
    return {
      clearTargetGroups(){
        self.targetGroups.clear();
      },
      setBrandId(id) {
        self.brandId = id;
      },
      reset(){
        applySnapshot(self, NoticeStoreInitial.entity);
      },
      clearStartEnd(){
        self.start = null;
        self.end = null;
      },
      edit(name, value) {
        self[name] = value;
      },
      onTargetGroupChange(checked, targetGroup) {
        console.log("targetGroup", targetGroup.toJSON());
        if (checked) {
          self.targetGroups.push(targetGroup);
        } else {
          const index = self.targetGroups.findIndex(
            (t) => t.id === targetGroup.id
          );
          if (index > -1) self.targetGroups.splice(index, 1);
        }
      },

      fetchEdit: flow(function* (id) {
        const result = yield axios.get(`notice/edit/${id}`);
        if(result.data.files.length > 0)
           self.files.load(result.data.files);
        else if(result.data.files.length === 0){
            self.files.clear();
        }

        const storeRoot = getParent(self, 1);

        result.data.targetGroups.length > 0 &&
          result.data.targetGroups.forEach((_) => {
            const aTg = storeRoot.availableTargetGroups.find(
              (t) => t.id === _.id
            );
            self.targetGroups.push(aTg);
          });

        Object.keys(result.data).forEach((key) => {
          if (
            self.hasOwnProperty(key) &&
            key !== "files" &&
            key !== "targetGroups"
          )
            self[key] = result.data[key];
        });
      }),

      fetchView: flow(function* (id) {
        const result = yield axios.get(`notice/${id}`);
        console.log("fetchView result", result.data)
        if(result.data.files.length > 0)
           self.files.load(result.data.files);
        else if(result.data.files.length === 0){
            self.files.clear();
        } 

        Object.keys(result.data).forEach((key) => {
          if (
            self.hasOwnProperty(key) &&
            key !== "files"
          )
            self[key] = result.data[key];
        });
      }),

      create: flow(function* () {
        console.log("create ", self.toJSON());
        const dataToUpload = {
          ...self,
          // title:self.title,
          // content:self.content,
          fileIds: self.files.list.map((file) => file.id),
          targetGroupIds: self.targetGroups.map((_) => _.id),
          // start: self.start,
          // end: self.end,
          // expired:self.expired,
        };
        console.log("dataToUpload:", dataToUpload);
        const response = yield axios.post("notice", dataToUpload);
        // applySnapshot(self, NoticeStoreInitial.entity);
        return response.data;
      }),
      delete: flow(function* () {
        const response = yield axios.delete(`notice/${self.id}`);
        console.log("delete ", response);
      })
      ,
      update: flow(function* () {
        console.log("update ", self.toJSON());
        const dataToUpload = {
          id: self.id,
          title: self.title,
          content: self.content,
          fileIds: self.files.list.map((file) => file.id),
          targetGroupIds: self.targetGroups.map((_) => _.id),
          start: self.start,
          end: self.end,
          expired: self.expired,
          pinned: self.pinned,
        };
        console.log("dataToUpload:", dataToUpload);
        yield axios.put("notice", dataToUpload);
      }),
    };
  });

  export const NoticeIdTitleEntity = types.model("NoticeIdTitleEntity", {
    id: types.identifierNumber,
    title: types.string,
  })

export const NoticeStore = types
  .model("NoticeStore", {
    entity: NoticeEntity,
    availableTargetGroups: types.array(TargetGroupEntry),
    notices: types.array(NoticeIdTitleEntity),
    brands: types.array(BrandEntry),
  })
  .volatile(() => ({
    draftEntry: NoticeStoreInitial,
  }))
  .views((self) => ({
    get Title() {
      return self.title;
    },
    get Content() {
      return self.content;
    },
  }))
  .actions((self) => {
    return {

      fetchBrands: flow(function* (){
        const result = yield axios.get(`notice/brands`);
        self.brands = result.data;
      })
      ,
      handleBlur(fieldName) {
        self.touched[fieldName] = true;
      },

      // create: flow(function* () {
      //   console.log("create ", self.toJSON());
      //   yield axios.post("notice", clone(self));
      //   self = NoticeStoreInitial;
      // }),

      fetch: flow(function* (id) {
        const result = yield axios.get(`notice`);
        self.notices = result.data;
      }),

      fetchTargetGroups: flow(function* () {
        console.log("create ", self.toJSON());
        const response = yield axios.get("notice/target-groups");
        self.availableTargetGroups = response.data;
      }),
    };
  });

export default NoticeStore;
