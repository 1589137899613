import { types, flow, applySnapshot } from "mobx-state-tree";
import axios from "axios";

// const SERVER_BASE = `${process.env.REACT_APP_API_SERVER}/account`;

const SERVER_URL = window._env_.API_URL;
export const RegisterStoreInitial = {};

export const AccountStore = types
  .model("AccountStore", {
    success: false,
    error: types.maybeNull(types.string),
    isLoading: false,
  })
  .actions((self) => {
    return {
      // setBirthday: (birthDay) => {
      //   self.birthDay = birthDay;
      // },
      // setEmail: (email) => {
      //   self.email = email;
      // },
      // setNewPassword: (newPassword) => {
      //   self.newPassword = newPassword;
      // },
      // setConfirmPassword: (confirmPassword) => {
      //   self.confirmPassword = confirmPassword;
      // },
      clearError: () => {
        self.error = null;
      },
      setError: (error) => {
        self.error = error;
      },
      showSuccess: () => {
        self.success = true;
      },
      closeSuccess: () => {
        self.success = false;
      },
      assign: (values) => {
        applySnapshot(self, values);
      },

      sendConfirmEmail: flow(function* ({email}) {
        // applySnapshot(self, values);
        console.log("sendConfirmEmail AccountStore.self", self);
        self.isLoading = true;
        try {
          self.clearError();

        const response = yield axios
          .get(`${SERVER_URL}/account/send-confirm-email/` + email);

         self.success = true;
        }
        catch (err) {
          if(err.response.status === 400)
            {
              self.error = err.response.data
            }
        }
        self.isLoading = false;
          // .then((res) => {
          //   console.log("Confirm Email Token:", res.data);
          //   self.clearError();
          //   self.showSuccess();
          // })
          // .catch((error) => {
          //   if (error.response) {
          //     console.log("error.response", error.response);
          //     // The request was made and the server responded with a status code
          //     // that falls out of the range of 2xx
          //     console.log(error.response.data);
          //     console.log(error.response.status);
          //     console.log(error.response.headers);
          //     self.closeSuccess();
          //     self.setError(error.response.data);
          //   } else if (error.request) {
          //     // The request was made but no response was received
          //     // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          //     // http.ClientRequest in node.js
          //     console.log(error.request);
          //   } else {
          //     // Something happened in setting up the request that triggered an Error
          //     console.log("Error", error.message);
          //   }
          //   console.log(error.config);
        // self.activationSuccess = true;
      }),

      sendForgotEmail: flow(function* ({ email }) {
        // applySnapshot(self, values);
        self.isLoading = true;
        yield axios
          .get(`${SERVER_URL}/account/forgot-password/${email}`)
          .then((res) => {
            // console.log("Password Reset Token:", res.data);
            self.showSuccess();
          })
          .catch((error) => {
            if (error.response) {
              console.log("error.response", error.response);
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              self.closeSuccess();
              self.setError(error.response.data);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
            }
            console.log(error.config);
          });
        // self.activationSuccess = true;
        self.isLoading = false;
      }),

      changePwd: flow(function* ({ userId, token, newPassword }) {
        // applySnapshot(self, values);
        // console.log("activate AccountStore.self", self);
        yield axios
          .post(`${SERVER_URL}/account/reset-password` ,{ userId, resetPasswordToken:token, newPassword })
          .then((res) => {
            self.showSuccess();
          })
          .catch((error) => {
            if (error.response) {
              console.log("error.response", error.response);
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              self.closeSuccess();
              self.setError(error.response.data);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
            }
            console.log(error.config);
          });
        // self.activationSuccess = true;
      }),

      confirmEmail: flow(function* ({ userId, token, password }) {
        // applySnapshot(self, values);
        console.log("activate AccountStore.self", self);
        yield axios
          .post(`${SERVER_URL}/account/confirm-email` ,{ userId, token, password })
          .then((res) => {
            console.log("confirmEmail Success");
            self.showSuccess();
          })
          .catch((error) => {
            if (error.response) {
              console.log("error.response", error.response);
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              self.closeSuccess();
              self.setError(error.response.data);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
            }
            console.log(error.config);
          });
        // self.activationSuccess = true;
      }),
    };
  });
