import { types, flow } from "mobx-state-tree";
import axios from "../../../store/axios";
import DateTime from "../../../store/DateTime";
import {ValueLabelEntry} from '../../../store/ValueLabelEntry'
import { values } from "mobx";

export const TraineeTestApplyStoreInitial = {
  location:"",
  startAusbildung: null,
  endAusbildung:null,
  disabledConfirmed: false,
  absenceDays: 0,
  testName: 1,
  title:"",
  state: null,
  date: null,
};

export const TraineeTestApplyStore = types
  .model("TraineeTestApplyStore", {
    traineeTestApplyId: types.maybeNull(types.integer),
    startAusbildung: types.maybeNull(DateTime),
    endAusbildung: types.maybeNull(DateTime),
    disabledConfirmed: types.boolean,
    absenceDays: types.maybeNull(types.integer),
    testName: types.number,
    location: types.maybeNull(types.string),
    state: types.maybeNull(types.string),
    selectedTrainerId: types.maybeNull(types.integer),
    trainers: types.array(ValueLabelEntry),
    trainer: types.maybeNull(ValueLabelEntry),
    date: types.maybeNull(DateTime),
  })
  .views((self) => ({
    get Trainers() {
      return values(self.trainers);
    }
  }))
  .actions((self) => {
    return {
      edit(name, value) {
        self[name] = value;
      },
      selectTrainer(valueLabel){
        self.selectedTrainer = valueLabel;
      },
      fetchTrainers: flow(function* () {
        const result = yield axios.get('/member/my-trainers')
        self.trainers = result.data.map(_ => ({label: _.name, value: _.id}));
      }),
        
      fetchEdit: flow(function* (id) {
        const result = yield axios.get(`/form/trainee-test-apply-review/${id}`);
        console.log("fetchEdit", result.data);
        // self  = {...result.data, {selectedTrainerId: result.data.trainerId}}
        self.traineeTestApplyId = result.data.traineeTestApplyId
        self.currentYear = result.data.currentYear;
        self.startAusbildung = result.data.startAusbildung;
        self.endAusbildung = result.data.endAusbildung;
        self.disabledConfirmed = result.data.disabledConfirmed;
        self.absenceDays = result.data.absenceDays;
        self.testName = result.data.testName;
        self.location = result.data.location;
        self.selectedTrainerId = result.data.trainerId;
        self.date = result.data.date;
      }),


      clearState() {
        self.state = null;
        self.location = "";
        self.startAusbildung = null;
        self.endAusbildung = null;
        self.disabledConfirmed = false;
        self.absenceDays = 0;
        self.testName = 1;
        self.date = null;
      },

      saveTraineeSignature: flow(function* ({testName, signature, currentYear }) {
        try {
          yield axios.post(`/form/trainee-test-apply-submit`, {
            currentYear,
            signature,
            startAusbildung: self.startAusbildung,
            endAusbildung: self.endAusbildung,
            disabledConfirmed: self.disabledConfirmed,
            absenceDays: self.absenceDays,
            testName,
            location: self.location,
            trainerId: self.selectedTrainerId,
            date: self.date,
          });
          self.state = "success";
        } catch (err) {
          self.state = "error";
        }
      }),

      update: flow(function* ({id, signature }) {
          console.log("update absenceDays", self.absenceDays);
        try {
          yield axios.patch(`/form/trainee-test-apply/${id}`, {
            signature:signature,
            startAusbildung: self.startAusbildung,
            endAusbildung: self.endAusbildung,
            disabledConfirmed: self.disabledConfirmed,
            absenceDays: self.absenceDays,
            testName: self.testName,
            location: self.location,
            trainerId: self.selectedTrainerId,
            date: self.date,
          });
          self.state = "success";
        } catch (err) {
          self.state = "error";
        }
      }),
    };
  });

export default TraineeTestApplyStore;
