import { getParent, destroy, types, flow, clone, applySnapshot } from "mobx-state-tree";
import { values } from "mobx";
import axios from "../../store/axios";
import dayjs from "dayjs";
import DateTime from "src/store/DateTime";
import FileStore from "../../store/FileStore";
import { FieldModel } from '../../store/ListField.model';
import {ValueLabelEntry} from '../../store/ValueLabelEntry'


export const ImportRecordBookStoreInitial = {
  entity: {
    start: null,
    end: null,
    category: null,
    semester: null,
    subject: null,
    trainee: null,
    files: { list: []},
    error: null,
},
  categories:[],
  semesters:[],
  subjects:[],

  list: [],
  fields:[
    {key:"start", label:"시작"},
    {key:"end", label:"종료"},
    {key:"subjectIndex", label:"Index"},
    {key:"trainer", label:"트레이너"},
    {key:"created", label:"작성일"},
    {key:"delete", label:"삭제"},
  ]
,
  error: "",
  total: 0
// selectedBrand: null,
// selectedDealer: null,
// selectedBranch: null,
};

export const ImportRecordBookListEntry = types.model("ImportRecordListEntry", {
  id: types.integer,
  start: types.maybeNull(DateTime),
  end: types.maybeNull(DateTime),
  subjectIndex: types.string,
  subject: types.string,
  trainer: types.string,
  created: types.maybeNull(DateTime),
})

export const ImportRecordBookEntity = types.model("ImportRecordBookEntity", {
  start: types.maybeNull(DateTime),
  end: types.maybeNull(DateTime),
  category: types.maybeNull(ValueLabelEntry),
  semester: types.maybeNull(ValueLabelEntry),
  subject: types.maybeNull(ValueLabelEntry),
  trainer: types.maybeNull(ValueLabelEntry),
  files: FileStore,
  selectedCategoryId: types.maybeNull (types.integer),
  selectedSemesterId: types.maybeNull (types.integer),
  selectedSubjectId: types.maybeNull (types.integer),
  selectedTrainerId: types.maybeNull (types.integer),
  error: types.maybeNull (types.string),
  state: types.maybeNull (types.string),
})
.actions((self) => {
  return {
      edit(name, value) {
          console.log("ImportRecordBook edit", name, value)
        // if(name === "start" || name === "end"){
        //   if(dayjs(value).isAfter(dayjs('2021-08-20'))){
        //     self.error = "2021-08-20 이전 데이터만 입력 가능 합니다.";
        //     return false;
        //   }else{
        //     self[name] = value;
        //     self.error = null;
        //   }
        // }else{
        //   self.error = null;
        self[name] = value;
    //   }
    }
      ,
    setEnd(start){
      self.end = dayjs(start).add(4, "day");
    },
    clearError(){
      self.state = null;
    },
    create: flow(function*(){
        const data = {
          start: self.start,
          end: self.end,
          subjectId: self.selectedSubjectId,
          trainerId: self.selectedTrainerId,
          fileIds: self.files.list.map((file) => file.id),
        };
        console.log("dataToUpdate:", data);
      try{
        yield axios.post(`import-record-book`, data);
        self.state = "success";
        self.start = null;
        self.end = null;
        self.selectedSemesterId = null;
        self.selectedCategoryId = null;
        self.selectedSubjectId = null;
        self.selectedTrainer= null;
      } catch (err){
        self.state = "error";
      }
    })
  }
})
;

export const ImportRecordBookStore = types
  .model("ImportRecordBookStore", {
    entity: ImportRecordBookEntity,
    list:types.array( ImportRecordBookListEntry),
    fields: types.array(FieldModel),
    categories: types.array(ValueLabelEntry),
    semesters: types.array(ValueLabelEntry),
    subjects: types.array(ValueLabelEntry),
    trainers: types.array(ValueLabelEntry),
    state: types.maybeNull(types.string),
    total: types.integer,
  })
  .views((self) => ({
    get Categories() {
      return values(self.categories);
    }
    ,
    get Semesters() {
      return values(self.semesters);
    }
    ,
    get Subjects() {
      return values(self.subjects);
    }
    ,
    get Trainers() {
      return values(self.trainers);
    }
    ,
    get List() {
      return values(self.list);
    }
  }))
  .actions((self) => {
    return {
      clearError(){
        self.state = null;
      },
      delete: flow(function* (id) {
        yield axios.delete(`import-record-book/${id}`);
        const item = self.list.find(_ => _.id === id);
        destroy(item);
        // self.list = result.data;
      }),
      fetchList: flow(function* (page) {
        self.list.clear();
        const result = yield axios.get(`import-record-book/list?page=${page}&limit=20`);
        console.log("fetchList", result.data.list);
        self.list = result.data.list;
        self.pages = result.data.pages;
        return result.data.list;
      })
      ,
      selectCategory(valueLabel){
        self.selectedCategory = valueLabel;
        self.selectedSemester = null;
        self.selectedSubject = null;
      }
      ,
      selectSemester(valueLabel){
        self.selectedSemester = valueLabel;
        self.selectedSubject = null;
      }
      ,
      selectSubject(valueLabel){
        self.selectedSubject = valueLabel;
      },
      selectTrainer(valueLabel){
        self.selectedTrainer = valueLabel;
      }
      ,
      reset(){
        applySnapshot(self, ImportRecordBookStoreInitial);
      }
      ,
      fetchEdit: flow(function* () {
        const result = yield axios.get(`import-record-book/trainer`);
        applySnapshot(self.entity, result.data);
      })
      ,
      fetchSubject: flow(function* ({semesterId}) {
        const result = yield axios.get(`import-record-book/subjects/${semesterId}`);
        console.log("fetch subject", result.data);
        self.subjects = result.data.map(_ => ({label: _.name, value: _.id}));
      })
      ,
      fetchSemester: flow(function* ({categoryId}) {
        console.log("fetch categoryId", categoryId);
        const result = yield axios.get(`import-record-book/semesters/${categoryId}`);
        console.log("fetch semester ", result.data);
        self.semesters = result.data?.map(_ => ({label: _.name, value: _.id}));
        self.subjects = [];
      })
      ,
      fetchCategory: flow(function* () {
        const result = yield axios.get('import-record-book/categories');
        console.log("fetch category", result.data);
        self.categories = result.data.categories.map(_ => ({label: _.name, value: _.id}));
        self.trainers = result.data.trainers.map(_ => ({label: _.name, value: _.id}));
        // applySnapshot(self.brands, result.data);
      })
      ,
      handleBlur(fieldName) {
        self.touched[fieldName] = true;
      }
      ,
      create: flow(function* () {
        console.log("import report create ", self.entity.toJSON());
        try{
          yield axios.post("import-record-book", self.entity);
          self.state = "success";
        } catch (err) {
          self.state = "error";
        }
      })
      ,
    };
  });

export default ImportRecordBookStore;
