
import { types } from "mobx-state-tree";
import DateTime from "src/store/DateTime";

  export const TransferRequestStatusModel = types.model("TransferRequestStatus", {
    status: types.enumeration("status", ["NotRequested", "Requested", "Confirmed"]),
    dateTime: types.maybeNull(DateTime),
  });

export const TransferRequestStatusModelInitial = {
  status: "NotRequested",
  dateTime: null,
}
  export default TransferRequestStatusModel;
