import { getParent, types, flow } from "mobx-state-tree";
import { values } from "mobx";
import dayjs from "dayjs";
import axios from "../../store/axios";
import DateTime from '../../store/DateTime'
import { FieldModel } from '../../store/ListField.model';

export const NoticeListStoreInitial = {
  list: [],
  fields:[
    {key:"pinned", label:"공지"},
    {key:"title", label:"제목"},
    {key:"author", label:"글쓴이"},
    {key:"created", label:"작성일"},
  ]
};

const NoticeListEntry = types.model("NoticeListEntry", {
  id: types.identifierNumber,
  created: DateTime,
  title: types.string,
  author: types.string,
  pinned:types.boolean,
});


export const NoticeListStore = types
  .model("NoticeListStore", {
    list: types.array(NoticeListEntry),
    fields: types.array(FieldModel),
  })
  .views((self) => ({
    get List() {
      return values(self.list);
    },
    get Pages() {
      return self.pages;
    },
  }))
  .actions((self) => {
    return {
      fetch: flow(function* (page) {
        const res = yield axios.get(`/notice/list?_page=${page}&_limit=24`);
        console.log(res.data);
        self.list = res.data.list;
        self.pages = res.data.pages;
      }),
    };
  });

export default NoticeListStore;
