import { types, flow } from "mobx-state-tree";
import axios from "../../store/axios";
import FileStore from "../../store/FileStore";

export const ViewUploadModalStoreInitial = {
  templateId: 0,
    files: { list: [] },
};

export const ViewUploadModalStore = types
  .model("ViewUploadModalEntity", {
    templateId: types.integer,
    files: FileStore,
  })
  .views((self) => ({
    get Files() {
      return self.files.list.map((file) => ({ id: file.id, name: file.name }));
    },
  }))
  .actions((self) => {
    return {
      create: flow(function* ({templateId}) {
        console.log("create ", self.toJSON());
        const dataToUpload = {
          templateId,
          fileIds: self.files.list.map((file) => file.id),
        };
        console.log("dataToUpload:", dataToUpload);
        const response = yield axios.post("form/upload", dataToUpload);

        // applySnapshot(self, ViewUploadModalStoreInitial.entity);
        return response.data;
      }),

      download: flow(function* ({templateId, fileName}) {
       const response = yield axios.get(`form/download/${templateId}` , {
          responseType: 'blob', // important
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
      }),
    };
  });

export default ViewUploadModalStore;
