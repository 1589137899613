import {flow, types, keys, getSnapshot, getParent, destroy } from "mobx-state-tree";
import axios from "../../store/axios";
import { values, } from 'mobx'
import   DateTime  from '../../store/DateTime';
import dayjs from 'dayjs';
import FileStore from '../../store/FileStore';

const SERVER_URL = process.env.REACT_APP_API_SERVER;

export const CreateRecordBookStoreInitial = {
  subjectId: 0,
  trainerId: 0,
  lessonId: 0,
  files: {list: []},
  content:"",
  date: new Date(),
}

// const FileModel= types.model("FileModel",{
//   id: types.identifierNumber,
//   fileName: types.string,
// })


export const CreateRecordBookStore = types
  .model({
    subjectId: types.integer,
    lessonId: types.integer,
    trainerId: types.integer,
    files: FileStore,
    content: types.string,
    date: DateTime,
  })
  .actions((self) => ({
    // setFile(file) {
    //   // let entry = self.files.find((file) => file.id === file)
    //   self.files.push(file);
    // },

    setContent(content) {
      self.content = content;
    },

    // deleteFile: flow(function* (file) {
    //  console.log('deleteFile', file)
    //  const url = `${SERVER_URL}/file/${file.id}`;
    //  yield axios.delete(url);
    //  destroy(file);
    // }),

    // upload: flow(function* (e) {
    //   if (e.target.files.length === 0) {
    //    return;
    //   }
    //   let formData = new FormData();
    //   formData.append("formFile", e.target.files[0]);
    //   formData.append("fileName", e.target.files[0].name);

    //   const authToken = JSON.parse(sessionStorage.getItem("tokens")).authToken;

    //   const res = yield axios
    //     .post(`${SERVER_URL}/file/upload`, formData, {
    //       headers: { Authorization: `Bearer ${authToken}` },
    //     });
    //   self.files.push({...res.data})
    // }),

    reset(){
     self = CreateRecordBookStoreInitial;
    },

    save: flow(function* (content) {
      const rootStore  = getParent(self);
      const {lessonId, subjectId, trainerId, start} = rootStore.traineeCalendarStore.selectedEvent;
      const authToken = JSON.parse(sessionStorage.getItem("tokens")).authToken;

      const createdRecordBook = {content, lessonId, subjectId, trainerId, date:start, fileIds:self.files.Ids}
      // self.lessonId = lessonId;
      // self.subjectId = subjectId;
      // self.trainerId = trainerId;
      // self.date = start;

      const response = yield axios
        .post(`record-book/create`, createdRecordBook);
          rootStore.traineeCalendarStore.createRecordBook(response.data);
    }),
  }));
