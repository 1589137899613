import {
  getParent,
  types,
  flow,
  clone,
  applySnapshot,
  destroy,
} from "mobx-state-tree";
import { values } from "mobx";
import axios from "../../store/axios";
import dayjs from "dayjs";
import moment from "moment";
import DateTime from "src/store/DateTime";
import FileStore from "../../store/FileStore";
import {
  SubjectEntity,
  SubjectEntityInitial,
} from "../../views/lesson/LessonList.store";
import { setDefaultLocale } from "react-datepicker";

export const LessonInfo = types.model("LessonInfo", {
  subjectTitle: types.string,
  subjectIndex: types.string,
  categoryTitle: types.string,
  description: types.maybeNull(types.string),
  action: types.maybeNull(types.string),
  trainer: types.string,
});

export const RecordBookStoreInitial = {
  entity: {
    id: 0,
    files: { list: [] },
    content: "",
    status: "",
    date: null,
  },
  lesson: {
    subjectTitle: "",
    subjectIndex: "",
    categoryTitle: "",
    description: "",
    action: "",
    trainer: ""
  },
  error: "",
};

export const RecordBookEntity = types
  .model("RecordBookEntity", {
    id: types.integer,
    files: FileStore,
    content: types.string,
    date: types.maybeNull(DateTime),
  })
  .views((self) => ({
    get Files() {
      return self.files.list.map((file) => ({ id: file.id, name: file.name }));
    },
  }))
  .actions((self) => {
    return {
      reset() {
        applySnapshot(self, RecordBookStoreInitial.entity);
      },
      edit(name, value) {
        self[name] = value;
      },
      fetchEdit: flow(function* (id) {
        console.log("record-book/edit/${id}", id);
        let result;
        try {
          self.files = {list:[]}
          result = yield axios.get(`record-book/edit/${id}`);
          result.data.files.length > 0 && self.files.load(result.data.files);
          self.content = result.data.content;
          self.id = result.data.id;
        } catch (error) {
          if (error.response.status === 404) {
            const parent = getParent(self, 1);
            parent.setError("존재 하지 않는 레코드 북입니다.");
          }
        }
      }),
      fetchView: flow(function* (id) {
        try{
        self.files = {list:[]}
        const result = yield axios.get(`record-book/${id}`);
        // const result = yield axios.get(`record-book/690`);
        console.log(result.data);
        result.data.files.length > 0 && self.files.load(result.data.files);

        self.subject = result.data.subject;
        self.content = result.data.content;
        self.status = result.data.status;
        } catch (error) {
          if(error.response && error.response.status === 404){
            const parent = getParent(self, 1);
            parent.setError("존재 하지 않는 레코드 북입니다.");
          }
        }
      }),
      create: flow(function* ({ lessonId, date, trainerId }) {
        console.log("RecordBook store create ", self.toJSON());
        const dataToUpload = {
          content: self.content,
          fileIds: self.files.list.map((file) => file.id),
          lessonId,
          date,
          trainerId,
        };
        console.log("dataToUpload:", dataToUpload);
        const response = yield axios.post("record-book", dataToUpload);
        // applySnapshot(self, RecordBookStoreInitial.entity);
        return response.data;
      }),

      update: flow(function* () {
        console.log("update ", self.toJSON());
        const dataToUpload = {
          id: self.id,
          content: self.content,
          fileIds: self.files.list.map((file) => file.id),
        };
        console.log("dataToUpload:", dataToUpload);
        yield axios.put("record-book", dataToUpload);
      }),
    };
  });

export const RecordBookIdTitleEntity = types.model("RecordBookIdTitleEntity", {
  id: types.identifierNumber,
  title: types.string,
});

export const RecordBookStore = types
  .model("RecordBookStore", {
    entity: RecordBookEntity,
    lesson: LessonInfo,
    error: types.maybeNull(types.string),
  })
  .volatile(() => ({
    draftEntry: RecordBookStoreInitial,
  }))
  .views((self) => ({
    get Title() {
      return self.title;
    },
    get Content() {
      return self.content;
    },
  }))
  .actions((self) => {
    return {
      setError(error) {
        self.error = error;
      },
      fetchLesson: flow(function* (lessonId) {
        const response = yield axios.get(`record-book/lesson/${lessonId}`);
        console.log("fetchLesson:", response);
        self.lesson = response.data;
      }),
      delete: flow(function* (recordBookId) {
        const response = yield axios.delete(`record-book/${recordBookId}`);
        console.log("delete:", response);
        const root = getParent(self, 1);
        root.traineeCalendarStore.deleteRecordBookEvent(recordBookId);
      }),
      handleBlur(fieldName) {
        self.touched[fieldName] = true;
      },
    };
  });

export default RecordBookStore;
