import { getParent, types, flow, clone, applySnapshot } from "mobx-state-tree";
import { values } from "mobx";
import axios from "../../../store/axios";
import dayjs from "dayjs";
import DateTime from "src/store/DateTime";
import FileStore from "../../../store/FileStore";

export const LaborContractFormStoreInitial = {
  laborContractFormEntity: {
    id: 0,
    state: "미제출",
    files: { list: [] },
  },
};

export const LaborContractFormEntity = types
  .model("LaborContractFormEntity", {
    id: types.integer,
    files: FileStore,
    state: types.enumeration("state", ["미제출", "확인요청", "확인완료"]),
  })
  .views((self) => ({
    get Files() {
      return self.files.list.map((file) => ({ id: file.id, name: file.name }));
    },
  }))
  .actions((self) => {
    return {
      fetchEdit: flow(function* (id) {
        const result = yield axios.get(`notice/edit/${id}`);
        result.data.files.length > 0 &&
          result.data.files.forEach((_) => self.files.list.push(_));
        const storeRoot = getParent(self, 1);

        result.data.targetGroups.length > 0 &&
          result.data.targetGroups.forEach((_) => {
            const aTg = storeRoot.availableTargetGroups.find(
              (t) => t.id === _.id
            );
            self.targetGroups.push(aTg);
          });

        Object.keys(result.data).forEach((key) => {
          if (
            self.hasOwnProperty(key) &&
            key !== "files" &&
            key !== "targetGroups"
          )
            self[key] = result.data[key];
        });
      }),

      fetchState: flow(function* (id) {
        const result = yield axios.get(`notice/${id}`);
        result.data && result.data.files?.length > 0 &&
          result.data.files.forEach((_) => self.files.list.push(_));

        Object.keys(result.data).forEach((key) => {
          if (
            self.hasOwnProperty(key) &&
            key !== "files"
          )
            self[key] = result.data[key];
        });
      }),

      fetchView: flow(function* (id) {
        const result = yield axios.get(`notice/${id}`);
        result.data.files.length > 0 &&
          result.data.files.forEach((_) => self.files.list.push(_));

        Object.keys(result.data).forEach((key) => {
          if (
            self.hasOwnProperty(key) &&
            key !== "files"
          )
            self[key] = result.data[key];
        });
      }),

      create: flow(function* () {
        console.log("create ", self.toJSON());
        const dataToUpload = {
          ...self,
          fileIds: self.files.list.map((file) => file.id),
          docType: "LABOR_CONTRACT",
        };
        console.log("dataToUpload:", dataToUpload);
        const response = yield axios.post("doclib/labor-contract", dataToUpload);
        self.state = "확인요청";
        return response.data;
      }),

      update: flow(function* () {
        console.log("update ", self.toJSON());
        const dataToUpload = {
          id: self.id,
          fileIds: self.files.list.map((file) => file.id),
        };
        console.log("dataToUpload:", dataToUpload);
        yield axios.put("notice", dataToUpload);
      }),
    };
  });

export const LaborContractFormStore = types
  .model("LaborContractStore", {
    laborContractFormEntity: LaborContractFormEntity,
  })
  .volatile(() => ({
    draftEntry: LaborContractFormStoreInitial,
  }))
  .actions((self) => {
    return {
      handleBlur(fieldName) {
        self.touched[fieldName] = true;
      },

      create: flow(function* () {
        console.log("create ", self.toJSON());
        yield axios.post("forms/labor-contract", clone(self));
      }),

      fetch: flow(function* (id) {
        const result = yield axios.get(`forms/labor-contract/${id}`);
        result.data.files.length > 0 &&
          result.data.files.foreEach((_) => self.files.set(_));
        Object.keys(result.data).forEach((key) => {
          if (self.hasOwnProperty(key) && key !== "files")
            self[key] = result.data[key];
        });
      }),

      update: flow(function* () {
        console.log("draftEntry update:", self.draftEntry);
        // yield axios.post("notice", noticeData);
      }),
    };
  });

export default LaborContractFormStore;
