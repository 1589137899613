import {flow, types, applySnapshot, getParent } from "mobx-state-tree";
import axios from "../../store/axios";
import { values, } from 'mobx'
import   DateTime  from '../../store/DateTime';

const SERVER_URL = process.env.REACT_APP_API_SERVER;

export const ConfirmRecordBookStoreInitial = {
  id: 0,
  subjectTitle:"",
  lessonId:0,
  traineeName: "",
  files: [],
  content:"",
  date: new Date(),
  signature:""
}

const FileModel= types.model("FileModel",{
  id: types.identifierNumber,
  fileName: types.string,
})

export const ConfirmRecordBookStore = types
  .model({
    id: types.integer,
    lessonId: types.integer,
    subjectTitle: types.string,
    traineeName: types.string,
    files: types.array(FileModel),
    content: types.maybeNull(types.string),
    date: DateTime,
    selectedFile: types.safeReference(FileModel),
    signature: types.maybeNull(types.string),
  })
.views((self) => ({
  get IsConfirmed() {
    return self.signature
  },
  get Files() {
    return values(self.files)
  },
  get SignatureData() {
    return self.signature && "data:image/png;base64,"+ self.signature
  }
}))
  .actions((self) => ({
    confirm: flow(function* () {
      yield axios
        .post(`/record-book/confirm`, {recordBookId:self.id} )
      const root = getParent(self, 1);
      root.lessonListStore.setConfirmed({lessonId:self.lessonId, recordBookId:self.id});
    }),
    selectFile: (file) => {
      self.selectedFile = file;
    },
    save: flow(function* (signature) {
      yield axios
        .post(`/record-book/signature`, {recordBookId:self.id, signature} )
      const root = getParent(self, 1);
      root.lessonListStore.setConfirmed({lessonId:self.lessonId, recordBookId:self.id});
    }),
    fetch: flow(function*({recordBookId}){
      const response = yield axios
        .get(`/record-book/${recordBookId}`)
      applySnapshot(self, response.data);
    }),
    download: flow(function* (file) {
      const response = yield axios.get(`file/download/${file.id}`, {
        responseType: "blob", // important
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file.fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
    }),
  }));
