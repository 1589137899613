import { types, flow, destroy, getParent } from "mobx-state-tree";
import { values } from "mobx";
import dayjs from "dayjs";
import axios from "../../store/axios";
import DateTime from "../../store/DateTime";
import FileStore from "../../store/FileStore";
// import { DateTime } from '../../store/DateTime'

export const TraineeCalendarStoreInitial = {
  events: {},
  selectedEvent: null,
};

const LessonEventEntry = types
  .model("LessonEventEntry", {
    id: types.identifier,
    subjectId: types.integer,
    trainerId: types.integer,
    lessonId: types.integer,
    start: DateTime,
    end: DateTime,
    title: types.string,
    eventType: types.literal("LESSON"),
  })
  .actions((self) => {
    return {
      setContent(content) {
        self.content = content;
      },
    };
  });

//Lesson객체가 버튼 통해서 계속 전달 됨
const RecordBookEventEntry = types.compose(
  LessonEventEntry,
  types
    .model("RecordBookEventEntry", {
      recordBookId: types.integer, //생성전에는 없음
      state: types.maybeNull(
        types.enumeration("state", ["확인요청", "확인완료"])
      ),
      eventType: types.enumeration("eventType", ["RECORD_BOOK", "NEW_BUTTON"]),
    })
    .actions((self) => {
      return {
        changeToRequest: (createdId) => {
          self.recordBookId = createdId; //TODO: server post and return created id
          self.eventType = "RECORD_BOOK";
          self.state = "확인요청";
        },
      };
    })
);

const EventEntry = types.union(
  // NewRecordBookButtonEventEntry,
  RecordBookEventEntry,
  LessonEventEntry
);

export const TraineeCalendarStore = types
  .model("TraineeCalendarStore", {
    // lessons: types.array(LessonEventEntry),
    // recordBooks: types.array(RecordBookEventEntry),
    events: types.optional(types.map(EventEntry), {}),
    selectedEvent: types.maybeNull(types.safeReference(EventEntry)),
  })
  .views((self) => ({
    get Events() {
      return values(self.events);
    },
  }))
  .actions((self) => {
    return {
      removePlusButton() {
        if (self.selectedEvent) {
          destroy(self.selectedEvent);
        }
      },
      deleteRecordBookEvent: (recordBookId) => {
        const destroyEvent = self.events.get("RECORD_BOOK" + recordBookId);
        destroy(destroyEvent);
      },
      setPrevMonth: flow(function* () {
        const prevStart = dayjs(self.start)
          .diff(-1, "month")
          .format("YYYY-MM-DD");
        const prevEnd = dayjs(self.end).diff(-1, "month").format("YYYY-MM-DD");
        // dayjs(self.end).diff(-1, "month")
        yield self.fetch(prevStart, prevEnd);
      }),
      createRecordBook: (createdRecordId) => {
        self.selectedEvent.recordBookId = createdRecordId;
        self.selectedEvent.eventType = "RECORD_BOOK";
        self.selectedEvent.state = "확인요청";
        self.selectedEvent.title = "확인요청중";
        // self.events.put(self.selectedEvent);
      },

      setSelectedEvent: (evt) => {
        self.selectedEvent = evt;
      },

      getThisMonth: flow(function* (traineeId) {
        const currDate = new Date();
        const currYear = currDate.getFullYear();
        const currMonth = currDate.getMonth();

        console.log("getThisMonth", currYear, currMonth, traineeId);
        if (traineeId) {
          yield self.fetch(currYear, currMonth, traineeId);
        } else {
          yield self.fetch(currYear, currMonth);
        }
      }),

      fetchLessons: flow(function* (year, month, traineeId) {
        let res;
          console.log("fetchLessons traineeId", traineeId)
        if (traineeId) {
          res = yield axios.get(`/lesson/${year}/${month}/${traineeId}`);
          console.log("fetchLessons res", res)
        } else {
          res = yield axios.get(`/lesson/${year}/${month}`);
        }
        const lessons = res.data;

        lessons.forEach((lesson) => {
          self.events.put({
            ...lesson,
            lessonId: lesson.id,
            id: "LESSON_" + lesson.id.toString(),
            eventType: "LESSON",
          });
        });

        return lessons;
        //각 레슨별 하위에 레코드북 들어갈 자리 마련
      }),

      fetchRecordBooks: flow(function* (year, month, lessons, traineeId) {
        console.log("fetchRecordBooks", year, month, lessons, traineeId);

        const result = [];
        lessons.forEach((lesson) => {
          //종료일과 시작일이 같다면 하루
          if (
            new Date(lesson.start).getDate() === new Date(lesson.end).getDate()
          ) {
            result.push({ ...lesson, lessonId: lesson.id });
          } else {
            for (
              let index = 0;
              index < dayjs(lesson.end).diff(dayjs(lesson.start), "day") + 1;
              index++
            ) {
              result.push({
                ...lesson,
                lessonId: lesson.id,
                start: dayjs(lesson.start).add(index, "day"),
                end: dayjs(lesson.start).add(index, "day"),
              });
            }
          }
          // console.log("💁‍♀️ lesson to event spread result", result);
        });

        let rbs_res;
        if (traineeId) {
          rbs_res = yield axios.get(
            `/record-book/trainee/${year}/${month}/${traineeId}`
          );
        } else {
          rbs_res = yield axios.get(`/record-book/trainee/${year}/${month}`);
        }
        const profileStore = getParent(self, 1).profileStore;

        //RecordBook
        result.forEach((baseButtonDay) => {
            // console.log("baseButtonDay", baseButtonDay);
          const foundRecordBook = rbs_res.data.find(
            (m) =>
              new Date(m.date).getMonth() ===
                new Date(baseButtonDay.start).getMonth() &&
              new Date(m.date).getDate() ===
                new Date(baseButtonDay.end).getDate()
          );
          if (foundRecordBook) {
            // console.log("foundRecordBook", foundRecordBook);
            self.events.put({
              ...baseButtonDay,
              id: "RECORD_BOOK" + foundRecordBook.id.toString(),
              subjectId: baseButtonDay.subjectId,
              eventType: "RECORD_BOOK",
              state: foundRecordBook.state,
              // id: `${baseButtonDay.subjectId}_${baseButtonDay.start.getDate()}`,
              recordBookId: foundRecordBook.id,
              // title:
              //   foundRecordBook.state === "확인요청"
              //     ? "확인 요청중"
              //     : foundRecordBook.state === "확인완료"
              //     ? "확인 완료"
              //     : "시작전",
              title: foundRecordBook.title,
            });
          } else if (profileStore.userType === "Trainee") {
            // + 버튼 Trainee일때
            self.events.put({
              ...baseButtonDay,
              id: `${baseButtonDay.subjectId}_${baseButtonDay.start}`,
              eventType: "NEW_BUTTON",
              state: null,
              recordBookId: 0,
            });
          }
        });
      }),

      fetch: flow(function* (year, month, traineeId) {
          console.log("fetch", year, month, traineeId);
        self.events.clear();
        if (traineeId) {
          const lessons = yield self.fetchLessons(year, month, traineeId);
          yield self.fetchRecordBooks(year, month, lessons, traineeId);
        } else {
          const lessons = yield self.fetchLessons(year, month);
            // console.log("TraineeCalendarStore fetch lessons", lessons)
          yield self.fetchRecordBooks(year, month, lessons);
            console.log("TraineeCalendarStore after fetchRecordBooks")
        }
      }),
    };
  });

export default TraineeCalendarStore;
